import { FunctionComponent, FormEvent, useEffect, useState } from "react";
import { DatePicker, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import moment from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (val: string) => void;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: { [key in string]: string };
  disabled?: boolean;
  value?: moment.Moment | undefined;
  format: string;
}

const DatePickerFormField: FunctionComponent<PropsType> = (
  props: PropsType
) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    readOnly,
    labelClassName,
    fieldClassName,
    onChange,
    disabled,
    value,
    format,
  } = props;
  const { t } = useTranslation();

  const i18nField =
    typeof field === "string"
      ? field.split(".")[field.split(".").length - 1]
      : Array.isArray(field)
      ? (field[field.length - 1] as string).replace(/[0-9]/g, "")
      : "";

  const key = `field_${String(field)}`.replace(",", "_");

  const [time, setTime] = useState<moment.Moment>();

  useEffect(() => {
    if (value != undefined) setTime(moment(value, format));
    else setTime(value);
  }, [value]);

  const handlePickTime = (val: moment.Moment) => {
    if (onChange) onChange(val.format(format));
  };

  return (
    <div className={"w-100 d-flex justify-content-between"}>
      {readOnly || showLabel ? (
        <span className={labelClassName ? labelClassName : ""}>
          {t(`${module}.form.fields.${i18nField}.label`)}&nbsp;
          {labelTooltip && <Tooltip title={labelTooltip} />}
        </span>
      ) : (
        ""
      )}
      <DatePicker
        locale={locale}
        id={key}
        value={time}
        showTime
        format={format}
        placeholder={format}
        onSelect={handlePickTime}
        disabled={readOnly || disabled}
        className={readOnly ? " readonly-form-field" : fieldClassName}
      />
    </div>
  );
};

export default DatePickerFormField;
