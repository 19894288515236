import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import SupervisionForm from "@views/supervisions/details/SupervisionForm";

interface LocationState {
  parentId: string;
}

const SupervisionAddScreen: FunctionComponent<RouteComponentProps> = () => {
  const location = useLocation<LocationState>();

  const [parentId, setParentId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (location.state) setParentId(location.state.parentId);
  }, [location]);

  return (
    <PageLayout>
      <>
        <PageBody hideCard>
          <SupervisionForm editMode parentId={parentId} />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default SupervisionAddScreen;
