import { createStore } from "effector";
import {
  authentifiedContextEvent,
  login,
  logout,
  setUser,
} from "@state/auth/AuthEvents";
import logger from "src/utils/logger";
import { AuthentifiedContext, AuthentifiedUser } from "@type/auth/auth.types";
import connectLocalStorage from "effector-localstorage";
import { authContext } from "../../adalConfig";
import { requestUpdateAuthentifiedContext } from "@state/auth/AuthEffects";

const authentifiedLocalStorage = connectLocalStorage(
  "authentifiedStore"
).onError((err) => console.log(err));

export const authentifiedStore = createStore<AuthentifiedContext | null>(
  authentifiedLocalStorage.init(0)
);

authentifiedStore
  .on(authentifiedContextEvent, (state, payload) => {
    return payload === undefined
      ? null
      : {
          ...payload,
        };
  })
  .on<AuthentifiedUser>(
    setUser,
    (state, payload: AuthentifiedUser): AuthentifiedContext => {
      return {
        ...state,
        user: { ...payload },
      };
    }
  );

authentifiedStore.watch(authentifiedLocalStorage);

authentifiedStore.on<void>(login, () => {
  logger.info("Login");
  requestUpdateAuthentifiedContext().then((response) => {
    if (response.ok && response.data) {
      setUser(response.data);
    }
  });
});

authentifiedStore.on<void>(logout, () => {
  logger.info("Remove access token");
  localStorage.clear();
  sessionStorage.clear();
  authContext.logOut();
  return null;
});
