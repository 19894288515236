import i18n from "i18next";
import { SupervisionNodeItemResponseDto } from "@state/supervisionnodes/dto/response/supervisionnode.item.response.dto";
import { ColumnsType } from "antd/lib/table/interface";

export const columns = (): ColumnsType<SupervisionNodeItemResponseDto> => [
  {
    title: i18n.t("supervisionnodes.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t("supervisionnodes.list.columns.hostname"),
    key: "hostname",
    dataIndex: "hostname",
    ellipsis: false,
  },
  {
    title: i18n.t("supervisionnodes.list.columns.updatedAt"),
    key: "updatedAt",
    dataIndex: "updatedAt",
    ellipsis: false,
  },
];
