import { FunctionComponent, useState } from "react";
import SupervisionForm from "./SupervisionForm";
import PageBody from "@components/bodies/PageBody";
import PageLayout from "@components/layouts/PageLayout";
import { RouteComponentProps, useParams } from "react-router-dom";

const SupervisionScreen: FunctionComponent<RouteComponentProps> = () => {
  const { supervisionId } = useParams<{ supervisionId: string }>();
  const { edit } = useParams<{ edit: string }>();
  const [editMode, setEditMode] = useState<boolean>(!!edit);
  return (
    <PageLayout>
      <>
        <PageBody hideCard>
          <SupervisionForm
            supervisionId={supervisionId ? supervisionId : ""}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default SupervisionScreen;
