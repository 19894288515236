import { createEffect } from "effector";
import { restGetUniqueHandler } from "@utils/rest/RestClient";
import configuration from "src/utils/Config";
import { AuthentifiedUser } from "@type/auth/auth.types";

export const requestUpdateAuthentifiedContext = createEffect({
  handler: restGetUniqueHandler<AuthentifiedUser>(
    configuration.backendBaseUrl + "/auth/me"
  ),
});
