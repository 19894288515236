import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { RecipientItemResponseDto } from "@state/recipients/dto/response/recipient.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { RecipientSaveRequestDto } from "@state/recipients/dto/request/recipient.save.request.dto";
import { RecipientDetailsResponseDto } from "@state/recipients/dto/response/recipient.details.response.dto";

const baseUrl = configuration.backendBaseUrl;
const recipientNodesUrl = baseUrl + "/recipients/";

export const requestGetRecipientPage = createEffect({
  handler: restListHandlerPagination<Pageable<RecipientItemResponseDto>>(
    recipientNodesUrl
  ),
});

export const requestGetRecipients = createEffect({
  handler: restListHandler<RecipientItemResponseDto>(recipientNodesUrl),
});

export const requestGetRecipient = createEffect({
  handler: restDetailsHandler<RecipientDetailsResponseDto>(recipientNodesUrl),
});

export const requestCreateRecipient = createEffect({
  handler: restCreationHandler<
    RecipientSaveRequestDto,
    RecipientDetailsResponseDto
  >(recipientNodesUrl),
});

export const requestUpdateRecipient = createEffect({
  handler: restUpdateHandler<
    RecipientSaveRequestDto,
    RecipientDetailsResponseDto
  >(recipientNodesUrl),
});

export const requestDeleteRecipient = createEffect({
  handler: restDeletionHandler(recipientNodesUrl),
});
