import { FunctionComponent, ReactNode } from "react";
import { Breadcrumb, Card } from "antd";
import { Link } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BodyProps {
  children: ReactNode;
  className?: string | null;
  hideCard?: boolean;
}

const PageBody: FunctionComponent<BodyProps> = (props: BodyProps) => {
  const { children, className, hideCard } = props;

  const buildLinkedBreadCrumb = (url: string, label: string) => {
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{label}</Link>
      </Breadcrumb.Item>
    );
  };

  const buildStaticBreadCrumb = (url: string, label: string) => {
    return <Breadcrumb.Item key={url}>{label}</Breadcrumb.Item>;
  };

  const breadcrumbNameMap = (url: string) => {
    switch (true) {
      case new RegExp("^/$").test(url):
        return buildLinkedBreadCrumb(url, "Supervisions");
      case new RegExp("^/supervisions$").test(url):
        return buildLinkedBreadCrumb(url, "Supervision");
      case new RegExp("^/supervisions/add$").test(url):
        return buildStaticBreadCrumb(url, "Création");
      case new RegExp("^/supervisions/[0-9]+$").test(url):
        return buildLinkedBreadCrumb(url, "Détails");
      case new RegExp("^/supervisions/[0-9]+/statistics$").test(url):
        return buildLinkedBreadCrumb(url, "Statistiques");
      case new RegExp("^/supervisionnodes$").test(url):
        return buildLinkedBreadCrumb(url, "Nodes");
      case new RegExp("^/supervisionsnodes/[0-9]+$").test(url):
        return buildLinkedBreadCrumb(url, "Détails");
      case new RegExp("^/clients$").test(url):
        return buildLinkedBreadCrumb(url, "Clients");
      case new RegExp("^/Clients/[0-9]+$").test(url):
        return buildLinkedBreadCrumb(url, "Détails");
      case new RegExp("^/users$").test(url):
        return buildLinkedBreadCrumb(url, "Utilisateurs");
      case new RegExp("^/users/[0-9]+$").test(url):
        return buildLinkedBreadCrumb(url, "Détails");
      default:
        return buildStaticBreadCrumb("", "");
    }
  };
  const location = window.location;
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const buildBreadCrumbs = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return breadcrumbNameMap(url);
  });

  const breadCrumbs = [
    <Breadcrumb.Item key="/">
      <Link to="/">
        <FontAwesomeIcon icon={faHome} />
      </Link>
    </Breadcrumb.Item>,
  ].concat(buildBreadCrumbs);

  return (
    <>
      <div>
        <Breadcrumb separator={" / "}>{breadCrumbs}</Breadcrumb>
        <br />
        <br />
      </div>

      <div className={`content-body ${className ? className : ""}`}>
        {hideCard ? children : <Card bordered={false}>{children}</Card>}
      </div>
    </>
  );
};

export default PageBody;
