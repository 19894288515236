import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { StatisticsItemResponseDto } from "@state/statistics/dto/response/statistics.item.response.dto";

import { Table } from "antd";
import "moment-duration-format";
import { optionsFr, shortFr } from "@utils/date/humanizeDuration";

export interface Props {
  statistics: StatisticsItemResponseDto | null;
}
const StatisticsAvailability: FunctionComponent<Props> = (props: Props) => {
  const { statistics } = props;
  const { t } = useTranslation();
  //const history = useHistory();
  const statColumns = [
    { key: "name", dataIndex: "name" },
    { key: "value", dataIndex: "value", align: "right" as "right" },
  ];
  const statData = [
    {
      name: t("statistics.expectedUpTime"),
      value: shortFr(statistics?.expectedUpTime, optionsFr),
    },
    {
      name: t("statistics.effectiveUpTime"),
      value:
        statistics?.expectedUpTime && statistics?.totalDownTime
          ? shortFr(
              statistics.expectedUpTime - statistics.totalDownTime > 0
                ? statistics.expectedUpTime - statistics.totalDownTime
                : 0,
              optionsFr
            )
          : shortFr(statistics?.expectedUpTime, {
              delimiter: " ",
              spacer: "",
              units: ["d", "h", "m"],
              round: true,
            }),
    },
    {
      name: t("statistics.totalDownTime"),
      value: shortFr(statistics?.totalDownTime, optionsFr),
    },
    {
      name: t("statistics.upTimeRate"),
      value:
        statistics?.expectedUpTime && statistics?.totalDownTime
          ? parseFloat(
              String(
                ((statistics.expectedUpTime - statistics.totalDownTime) /
                  statistics.expectedUpTime) *
                  100
              )
            ).toFixed(2) + "%"
          : "100%",
    },
    { name: t("statistics.timeDown"), value: statistics?.downNumber },
    {
      name: t("statistics.averageTime"),
      value: shortFr(statistics?.meanDownTime, optionsFr),
    },
  ];

  return (
    <>
      <Table
        showHeader={false}
        pagination={false}
        columns={statColumns}
        dataSource={statData}
      />
    </>
  );
};
export default StatisticsAvailability;
