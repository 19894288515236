import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { emptySearchResult } from "@utils/Constant";
import { Pageable } from "@type/pagination/pagination.types";
import { ClientItemResponseDto } from "@state/clients/dto/response/client.item.response.dto";
import { useTranslation } from "react-i18next";
import { message, Table } from "antd";
import { requestGetClientsPage } from "@state/clients/ClientEffects";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@components/lists/ClientsColumns";
import { RouteComponentProps, useHistory } from "react-router-dom";
import BasicButton from "@components/buttons/BasicButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { hasPermission } from "@utils/permission-helper";

const ClientsScreen: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  const [clients, setClients] = useState<Pageable<ClientItemResponseDto>>(
    emptySearchResult
  );

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const fetchClients = useCallback(() => {
    setDataHasLoaded(false);
    requestGetClientsPage({
      page: pageNumber - 1,
      limit,
    })
      .catch(() => {
        void message.error(t("clients.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    return requestGetClientsPage.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setClients(result.data);
      }
    });
  });

  return (
    <PageLayout>
      <>
        <PageBody>
          <div className="d-flex flex-column align-items-stretch flex-sm-row">
            <div className="w-100 d-flex justify-content-between mb-3 flex-column flex-sm-row">
              <h4 className="text-primary">{t("clients.title")}</h4>
              {hasPermission("clients", true) && (
                <BasicButton
                  variant={"default"}
                  text={t("clients.actions.add")}
                  onClick={() => history.push("/clients/add")}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  iconLeft={false}
                />
              )}
            </div>
          </div>
          <Table
            style={{ whiteSpace: "break-spaces" }}
            locale={{ emptyText: dataHasLoaded ? "" : " " }}
            loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
            columns={columns()}
            dataSource={clients.content}
            pagination={{
              total: clients.totalElements,
              pageSize: clients.size,
              current: clients.number + 1,
              onChange: setPageNumber,
              onShowSizeChange: handleChangeLimit,
              showSizeChanger: true,
            }}
            showSorterTooltip={false}
            rowKey={(record) => record.id}
            rowClassName="cursor-pointer"
            onRow={(record) => {
              return {
                onClick: () => history.push(`/clients/${record.id}`),
              };
            }}
          />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default ClientsScreen;
