import { useEffect, useState, FunctionComponent } from "react";
import { Col, Form, message, Modal, Popconfirm, Row, Spin } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Field } from "@type/form/field.types";
import { SupervisionNodeDetailsResponseDto } from "@state/supervisionnodes/dto/response/supervisionnode.details.response.dto";
import {
  requestCreateSupervisionNode,
  requestDeleteSupervisionNode,
  requestGetSupervisionNode,
  requestUpdateSupervisionNode,
} from "@state/supervisionnodes/SupervisionNodeEffects";
import { FIELD_LENGTH, formItemBlockLayout } from "@utils/Constant";
import { SupervisionNodeSaveRequestDto } from "@state/supervisionnodes/dto/request/supervisionnode.save.request.dto";
import InputFormField from "@components/inputs/InputFormField";
import { ValidateErrorEntity, Store } from "rc-field-form/lib/interface";
import BasicButton from "@components/buttons/BasicButton";
import { hasPermission } from "@utils/permission-helper";

const { confirm } = Modal;

export interface Props {
  supervisionNodeId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const SupervisionNodeForm: FunctionComponent<Props> = (props: Props) => {
  const { supervisionNodeId, editMode, setEditMode } = props;
  const { t } = useTranslation();

  const history = useHistory();

  const [form] = Form.useForm();

  const [
    supervisionNode,
    setSupervisionNode,
  ] = useState<SupervisionNodeDetailsResponseDto | null>(null);

  const [fields, setFields] = useState<Field[]>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] = useState<boolean>(
    false
  );

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async (action: string) => {
    return new Promise<boolean>((resolve) => {
      const nameField = form.getFieldValue("name") as string;

      confirm({
        title: t(`supervisionnodes.edit.modals.${action}.title`),
        content: t(`supervisionnodes.edit.modals.${action}.content`, {
          supervisionnode: `${nameField}`,
        }),
        okText: t("buttons.yes"),
        cancelText: t("buttons.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  useEffect(() => {
    if (supervisionNodeId) {
      void requestGetSupervisionNode(supervisionNodeId);
    }
  }, [supervisionNodeId]);

  useEffect(() => {
    return requestGetSupervisionNode.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        setSupervisionNode(result.data);
        setFields([
          {
            name: ["name"],
            value: result.data.name,
          },
          {
            name: ["hostname"],
            value: result.data.hostname,
          },
        ]);
      } else {
        void message.error(t("supervisionNodes.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestCreateSupervisionNode.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        void message.success(t("supervisionnodes.add.messages.success"));
        history.push("/supervisionnodes");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateSupervisionNode.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        setSupervisionNode(result.data);
        void message.success(t("supervisionnodes.edit.messages.success"));
        history.push("/supervisionnodes");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestDeleteSupervisionNode.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok) {
        void message.success(t("supervisionnodes.delete.messages.success"));
        history.push("/supervisionnodes");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  const handleSubmit = (values: Store) => {
    void confirmationPopup("save").then((confirmed: boolean) => {
      if (confirmed) {
        setButtonLoading(true);
        const supervisionNodeToSave: SupervisionNodeSaveRequestDto = {
          name: values.name as string,
          hostname: values.hostname as string,
        };
        if (supervisionNodeId) {
          void requestUpdateSupervisionNode({
            id: supervisionNodeId,
            dto: supervisionNodeToSave,
          });
        } else {
          void requestCreateSupervisionNode({
            dto: supervisionNodeToSave,
          });
        }
      }
    });
  };

  const handleDelete = () => {
    if (supervisionNodeId) {
      void confirmationPopup("delete").then((confirmed: boolean) => {
        if (confirmed) {
          void requestDeleteSupervisionNode({
            id: supervisionNodeId,
          });
        }
      });
    }
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    void message.error(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <>
      {!getResponseReceived && supervisionNodeId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !supervisionNodeId || supervisionNode ? (
        <>
          <Form
            {...formItemBlockLayout}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            fields={fields}
          >
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex justify-content-between mb-3 flex-column flex-sm-row">
                <h4 className="text-primary">
                  {t("supervisionnodes.details.title")}
                </h4>
                {hasPermission("supervisions", true) && (
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    {editMode && setEditMode && (
                      <Popconfirm
                        title={t("forms.actions.modals.cancel.content")}
                        okText={t("buttons.yes")}
                        cancelText={t("buttons.no")}
                        onConfirm={() => {
                          setEditMode(false);
                        }}
                        placement="top"
                        icon={<QuestionCircleOutlined />}
                        className="m-2"
                      >
                        <BasicButton
                          type={"reset"}
                          variant={"default"}
                          text={t("buttons.cancel")}
                          icon={<StopOutlined />}
                          iconLeft={false}
                        />
                      </Popconfirm>
                    )}
                    {editMode ? (
                      <BasicButton
                        variant={"primary"}
                        onClick={form.submit}
                        text={t("buttons.save")}
                        icon={<SaveOutlined />}
                        iconLeft={false}
                        isLoading={buttonLoading}
                      />
                    ) : (
                      supervisionNode &&
                      setEditMode && (
                        <>
                          <BasicButton
                            type={"reset"}
                            variant={"primary"}
                            text={t("buttons.edit")}
                            onClick={() => setEditMode(true)}
                            icon={<EditOutlined />}
                            iconLeft={false}
                            isLoading={buttonLoading}
                          />
                          <BasicButton
                            variant={"danger"}
                            text={t("buttons.delete")}
                            onClick={handleDelete}
                            icon={<DeleteOutlined />}
                            iconLeft={false}
                            isLoading={buttonLoading}
                            className={"ml-2"}
                          />
                        </>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
            <Row>
              <Col xs={24}>
                <InputFormField
                  showLabel
                  module="supervisionnodes.edit"
                  field="name"
                  required={true}
                  emptyErrorMessage={t("forms.errors.mandatory")}
                  readOnly={!editMode}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <InputFormField
                  showLabel
                  module="supervisionnodes.edit"
                  field="hostname"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^http.?://.+$"),
                      message: t("forms.errors.specifyProtocol"),
                    },
                  ]}
                  required={true}
                  emptyErrorMessage={t("forms.errors.mandatory")}
                  readOnly={!editMode}
                  maxLength={FIELD_LENGTH.HOSTNAME}
                />
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <div>{t("supervisionNodes.notFound")}</div>
      )}
    </>
  );
};
export default SupervisionNodeForm;
