import { FunctionComponent, ReactNode, useState } from "react";
import { Card } from "antd";

interface CardTabContainerProps {
  title?: string | ReactNode;
  className?: string | null;
  tabs?: { key: string; tab: string }[];
  content: { key: string; node: ReactNode }[];
  extra?: ReactNode;
}

const CardTabContainer: FunctionComponent<CardTabContainerProps> = (
  props: CardTabContainerProps
) => {
  const { title, className, tabs, content, extra } = props;
  const [state, setState] = useState<string>(content[0].key);
  let onTabChange = (key: string) => {
    setState(key);
  };

  return (
    <Card
      title={title}
      className={`shadow-sm rounded-1 my-4 ${className ? className : ""}`}
      bordered={false}
      headStyle={{ fontWeight: "bold", fontSize: "1.6em", border: "none" }}
      extra={extra}
      tabList={tabs}
      onTabChange={(key) => onTabChange(key)}
    >
      {content[content.findIndex((elem) => elem.key === state)].node}
    </Card>
  );
};

export default CardTabContainer;
