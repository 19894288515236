import { FunctionComponent, useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout } from "@state/auth/AuthEvents";
import { hasPermission } from "@utils/permission-helper";

const SideMenu: FunctionComponent = () => {
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState("home");

  const menu = [
    {
      key: "home",
      link: "/",
      text: t("menu.home"),
      hasPermission: true,
    },
    {
      key: "supervisionnodes",
      link: "/supervisionnodes",
      text: t("menu.supervisionnodes"),
      hasPermission: hasPermission("instances"),
    },
    {
      key: "clients",
      link: "/clients",
      text: t("menu.clients"),
      hasPermission: hasPermission("clients"),
    },
    {
      key: "users",
      link: "/users",
      text: t("menu.users"),
      hasPermission: hasPermission("users"),
    },
  ];

  useEffect(() => {
    const currentItem = menu.find(
      (item) =>
        window.location.pathname.split("/")[1] === item.link.split("/")[1]
    );
    if (currentItem) setSelectedKey(currentItem.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, menu]);

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]}
        style={{ height: "100%", width: "250px" }}
        className={"d-flex flex-column "}
      >
        {menu.map(
          (item) =>
            item.hasPermission && (
              <Menu.Item key={item.key}>
                <Link to={item.link}>{item.text}</Link>
              </Menu.Item>
            )
        )}
        <div key={"spacer"} className={"d-flex flex-grow-1"}></div>
        <Menu.Item
          key={"logout"}
          className={"d-block d-sm-none border-0 text-right pr-4"}
        >
          <div onClick={() => logout()}>{t("login.actions.logout")}</div>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SideMenu;
