import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerFilteredBy,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { RegMaintenancesItemResponseDto } from "@state/regmaintenances/dto/response/regmaintenances.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { RegMaintenancesSaveRequestDto } from "@state/regmaintenances/dto/request/regmaintenances.save.request.dto";
import { RegMaintenancesDetailsResponseDto } from "@state/regmaintenances/dto/response/regmaintenances.details.response.dto";

const baseUrl = configuration.backendBaseUrl;
const regMaintenancesUrl = baseUrl + "/regularmaintenances/";

export const requestGetRegMaintenancePage = createEffect({
  handler: restListHandlerPagination<Pageable<RegMaintenancesItemResponseDto>>(
    regMaintenancesUrl
  ),
});

export const requestGetRegMaintenances = createEffect({
  handler: restListHandler<RegMaintenancesItemResponseDto>(regMaintenancesUrl),
});

export const requestGetRegMaintenancesForSupervision = createEffect({
  handler: restListHandlerFilteredBy<RegMaintenancesItemResponseDto>(
    regMaintenancesUrl + "supervisions/"
  ),
});

export const requestGetRegMaintenance = createEffect({
  handler: restDetailsHandler<RegMaintenancesDetailsResponseDto>(
    regMaintenancesUrl
  ),
});

export const requestCreateRegMaintenance = createEffect({
  handler: restCreationHandler<
    RegMaintenancesSaveRequestDto,
    RegMaintenancesDetailsResponseDto
  >(regMaintenancesUrl),
});

export const requestUpdateRegMaintenance = createEffect({
  handler: restUpdateHandler<
    RegMaintenancesSaveRequestDto,
    RegMaintenancesDetailsResponseDto
  >(regMaintenancesUrl),
});

export const requestDeleteRegMaintenance = createEffect({
  handler: restDeletionHandler(regMaintenancesUrl),
});
