import { FunctionComponent, useState } from "react";
import SupervisionNodeForm from "./SupervisionNodeForm";
import PageBody from "@components/bodies/PageBody";
import PageLayout from "@components/layouts/PageLayout";
import { RouteComponentProps, useParams } from "react-router-dom";

const SupervisionNodeScreen: FunctionComponent<RouteComponentProps> = () => {
  const { supervisionNodeId } = useParams<{ supervisionNodeId: string }>();

  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout>
      <>
        <PageBody>
          <SupervisionNodeForm
            supervisionNodeId={supervisionNodeId ? supervisionNodeId : ""}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default SupervisionNodeScreen;
