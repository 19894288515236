const humanizeDuration = require("humanize-duration");
export const shortFr = humanizeDuration.humanizer({
  language: "shortFr",
  languages: {
    shortFr: {
      y: function (c: number) {
        return "an" + (c >= 2 ? "s" : "");
      },
      mo: () => "mois",
      w: function (c: number) {
        return "semaine" + (c >= 2 ? "s" : "");
      },
      d: () => "j",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});
export const optionsFr = {
  delimiter: " ",
  spacer: "",
  units: ["d", "h", "m"],
  round: true,
};
