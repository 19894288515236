import i18n from "i18next";

export interface FieldErrors {
  [field: string]: string;
}

export interface ParsedResponse<T> {
  data: T | undefined;
  responseCode: number | undefined;
  errorMessage: string | undefined;
  fieldErrors?: FieldErrors;
  ok: boolean;
}

export interface ErrorResponse {
  error: string;
  message?: any;
  statusCode: number;
}

export interface PropertyError {
  property: string;
  error: string;
}

export class ServerResponseParser<T> {
  parseResponse(response: Response): Promise<ParsedResponse<T>> {
    if (response.ok) {
      if (response.status === 204) {
        return Promise.resolve({
          responseCode: response.status,
          data: undefined,
          ok: true,
        } as ParsedResponse<T>);
      }

      return response.text().then((text: string) => {
        const data = (text && JSON.parse(text)) as T;
        return {
          responseCode: response.status,
          errorMessage: undefined,
          data,
          ok: true,
        };
      });
    } else {
      return response.text().then((text: string) => {
        const json = (text && JSON.parse(text)) as ErrorResponse;
        const result = {
          responseCode: response.status,
          data: undefined,
          ok: false,
        };
        if (response.status === 400) {
          const fieldErrors: FieldErrors = {};
          // const propertyErrors: PropertyError[] =
          //   json.message && this.getPropertyErrors(json.message);
          // propertyErrors.forEach(
          //   (propertyError: PropertyError) =>
          //     (fieldErrors[propertyError.property] = propertyError.error)
          // );
          return {
            ...result,
            errorMessage: i18n.t("error.badrequest"),
            fieldErrors,
          };
        } else if (response.status === 422) {
          const fieldErrors: FieldErrors = {};
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
          fieldErrors["file"] = `422.${json.message[0].id}`;
          return {
            ...result,
            errorMessage: i18n.t("error.unprocessableEntity"),
            fieldErrors,
          };
        } else {
          return {
            ...result,
            errorMessage: json.message
              ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                json.error + " : " + json.message
              : json.error,
          };
        }
      });
    }
  }

  // getPropertyErrors = (message: any[], property = ""): PropertyError[] => {
  //   const array = message.map((m: any): PropertyError[] => {
  //     if (m.constraints) {
  //       return Object.keys(m.contexts).map(key => ({
  //         property: `${property}${m.property}`,
  //         error: m.contexts[key].errorCode
  //       }));
  //     } else {
  //       return this.getPropertyErrors(m.children, property + m.property + ".");
  //     }
  //   });
  //   return array.flat();
  // };
}
