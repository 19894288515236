import { authentifiedStore } from "@state/auth/AuthStore";
import { PROFILES } from "@utils/enums/profile.enum";
import logger from "@utils/logger";

export const hasPermission = (category: string, canEdit?: boolean) => {
  const profile = authentifiedStore.getState()?.user?.profile;
  let permissions: PROFILES[] = [];
  switch (category) {
    case "users":
      permissions = [PROFILES.USERS_MANAGER];
      if (!canEdit) permissions.push(PROFILES.USERS_READER);
      break;
    case "clients":
      permissions = [PROFILES.CLIENTS_MANAGER];
      if (!canEdit) permissions.push(PROFILES.CLIENTS_READER);
      break;
    case "supervisions":
      permissions = [PROFILES.SUPERVISIONS_MANAGER];
      if (!canEdit) permissions.push(PROFILES.SUPERVISIONS_READER);
      break;
    case "instances":
      permissions = [PROFILES.INSTANCES_MANAGER];
      if (!canEdit) permissions.push(PROFILES.INSTANCES_READER);
      break;
    case "recipients":
      permissions = [PROFILES.RECIPIENTS_MANAGER];
      if (!canEdit) permissions.push(PROFILES.RECIPIENTS_READER);
      break;
    case "interruption_histories":
      permissions = [PROFILES.INTERRUPTION_HISTORIES_MANAGER];
      if (!canEdit) permissions.push(PROFILES.INTERRUPTION_HISTORIES_READER);
      break;
    default:
  }
  permissions.push(PROFILES.SUPER_ADMIN);
  logger.info(
    `Check user profile ${profile} against required permissions ${permissions} for category ${category}`
  );
  return profile?.some((r) => permissions.includes(r));
};
