import { FunctionComponent, FormEvent, useState, useEffect } from "react";
import { ConfigProvider, Form, List, message, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { RecipientSaveRequestDto } from "@state/recipients/dto/request/recipient.save.request.dto";
import {
  requestCreateRecipient,
  requestGetRecipients,
  requestUpdateRecipient,
} from "@state/recipients/RecipientEffects";
import { RecipientItemResponseDto } from "@state/recipients/dto/response/recipient.item.response.dto";
import { FIELD_LENGTH, formItemBlockLayout } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import { Field } from "@type/form/field.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import BasicButton from "@components/buttons/BasicButton";
import { FrownOutlined } from "@ant-design/icons";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onChange?: (value: RecipientItemResponseDto[]) => void;
  value?: RecipientItemResponseDto[];
}

const RecipientsFormField: FunctionComponent<PropsType> = (
  props: PropsType
) => {
  const { readOnly, value, onChange } = props;
  const { t } = useTranslation();

  const [options, setOptions] = useState<RecipientItemResponseDto[]>([]);

  const [availableOptions, setAvailableOptions] = useState<
    RecipientItemResponseDto[] | undefined
  >([]);

  const [recipients, setRecipients] = useState<RecipientItemResponseDto[]>([]);

  const [recipientModalVisible, setRecipientModalVisible] = useState<boolean>(
    false
  );

  const [recipientForm] = Form.useForm();

  const [recipientFields, setRecipientFields] = useState<Field[]>([]);

  useEffect(() => {
    setRecipients(value!);
  }, [value]);
  useEffect(() => {
    computeAvailableOptions();
  }, [recipients, options]);

  useEffect(() => {
    return requestGetRecipients.done.watch(({ result }) => {
      if (result.ok) {
        if (result.data) {
          setOptions(result.data);
          computeAvailableOptions();
        }
      } else {
        void message.error(t("recipients.notFound"));
      }
    });
  });

  const computeAvailableOptions = () => {
    let available = recipients
      ? options.filter(
          (item) => !recipients.filter((rec) => rec.id === item.id).length
        )
      : options;
    setAvailableOptions(available);
  };

  useEffect(() => {
    return requestCreateRecipient.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setRecipientModalVisible(false);
        requestGetRecipients({});
        //TODO ajouter le nouveau recipient dans le state pour l'enregistrer (/!\ voir pourquoi la ligne 94 marche pas)
        //setRecipients(recipients => recipients ? [...recipients, result.data! as RecipientItemResponseDto] : [result.data! as RecipientItemResponseDto]);
        clearRecipientForm();
      } else {
        void message.error(t("recipients.creationFailed"));
      }
    });
  });

  useEffect(() => {
    requestGetRecipients({});
  }, []);

  useEffect(() => {
    return requestUpdateRecipient.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setRecipientModalVisible(false);
        let index = recipients.findIndex((item) => item.id === result.data?.id);
        if (index !== -1) {
          var newRecipients = recipients.slice();
          newRecipients[index] = result.data;
          setRecipients([...newRecipients]);
        }
        requestGetRecipients({});
        // recipientForm.setFieldsValue(values);
        clearRecipientForm();
      } else {
        void message.error(t("recipients.error.creationFailed"));
      }
    });
  });

  const handleCreateRecipient = () => {
    let id = recipientForm.getFieldValue("id");
    const recipientToSave: RecipientSaveRequestDto = {
      label: recipientForm.getFieldValue("label"),
      email: recipientForm.getFieldValue("email"),
    };
    if (id) {
      void requestUpdateRecipient({
        id: id,
        dto: recipientToSave,
      });
    } else {
      void requestCreateRecipient({
        dto: recipientToSave,
      });
      //requestGetRecipients({});
    }
  };

  const clearRecipientForm = () => {
    setRecipientFields([
      { name: ["label"], value: "" },
      { name: ["email"], value: "" },
      { name: ["id"], value: null },
    ]);
  };

  const showRecipientsModal = () => {
    setRecipientModalVisible(true);
  };

  const handleCancelRecipientCreation = () => {
    setRecipientModalVisible(false);
  };

  const handleSelect = (val: string) => {
    let recipient = options?.find((item) => item.id === val);
    var newArr = recipients?.slice() ?? [];
    newArr.push(recipient!);
    setRecipients(newArr);
    if (onChange) onChange(newArr);
  };

  const handleDeselect = (val: string) => {
    let value = recipients?.filter((item) => item.id !== val) ?? [];
    setRecipients(value);
    if (onChange) onChange(value);
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <FrownOutlined style={{ fontSize: 20 }} />
      <p>{t("recipients.list.empty")}</p>
    </div>
  );

  return (
    <>
      {recipients && recipients.length ? (
        <List
          dataSource={recipients}
          renderItem={(item: RecipientItemResponseDto) => (
            <List.Item
              actions={
                !readOnly
                  ? [
                      <FontAwesomeIcon
                        icon={faEdit}
                        className={"action-button"}
                        onClick={() => {
                          setRecipientFields([
                            { name: ["label"], value: item.label },
                            { name: ["email"], value: item.email },
                            { name: ["id"], value: item.id },
                          ]);
                          setRecipientModalVisible(true);
                        }}
                      />,
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={"action-button"}
                        onClick={() => {
                          handleDeselect(item.id);
                        }}
                      />,
                    ]
                  : []
              }
            >
              <div>
                {item.label} ({item.email})
              </div>
            </List.Item>
          )}
        />
      ) : (
        "Aucun destinataire associé"
      )}
      {!readOnly && (
        <>
          {availableOptions && availableOptions.length > 0 && (
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <Select
                mode={"multiple"}
                style={{ width: "100%" }}
                showArrow={true}
                placeholder={"Ajouter un destinataire"}
                value={[]}
                disabled={readOnly}
                options={
                  availableOptions?.map((item) => {
                    return { value: item.id, label: item.label };
                  }) ?? []
                }
                onSelect={handleSelect}
                onDeselect={handleDeselect}
                filterOption={(inputValue, option) =>
                  option?.label
                    ? option?.label
                        .toString()
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    : false
                }
              />
            </ConfigProvider>
          )}
        </>
      )}
      <Form
        {...formItemBlockLayout}
        form={recipientForm}
        onFinish={handleCreateRecipient}
        onFinishFailed={() => alert("something's wrong")} // TODO: do something with that
        fields={recipientFields}
      >
        <div className={"d-flex w-100 align-content-end flex-column my-2"}>
          {!readOnly && (
            <BasicButton
              variant={"clear"}
              text={t("recipients.add.title")}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={showRecipientsModal}
              iconLeft={false}
            />
          )}
        </div>
        <Modal
          title={t("recipients.edit.modals.create.title")}
          visible={recipientModalVisible}
          onOk={handleCreateRecipient}
          cancelText={"Annuler"}
          onCancel={handleCancelRecipientCreation}
        >
          <InputFormField
            showLabel
            module="recipients.edit"
            field="label"
            emptyErrorMessage={t("forms.errors.mandatory")}
            readOnly={readOnly}
            maxLength={FIELD_LENGTH.NAME}
          />
          <InputFormField
            showLabel
            module="recipients.edit"
            field="email"
            emptyErrorMessage={t("forms.errors.mandatory")}
            readOnly={readOnly}
            maxLength={FIELD_LENGTH.NAME}
          />
        </Modal>
      </Form>
    </>
  );
};

export default RecipientsFormField;
