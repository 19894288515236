import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Col, Form, message, Modal, Row, Table, TableColumnType } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formItemBlockLayout } from "@utils/Constant";
import { Field } from "@type/form/field.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import BasicButton from "@components/buttons/BasicButton";
import { RegMaintenancesItemResponseDto } from "@state/regmaintenances/dto/response/regmaintenances.item.response.dto";
import { RegMaintenancesSaveRequestDto } from "@state/regmaintenances/dto/request/regmaintenances.save.request.dto";
import {
  requestCreateRegMaintenance,
  requestDeleteRegMaintenance,
  requestUpdateRegMaintenance,
} from "@state/regmaintenances/RegMaintenancesEffects";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import TimePickerFormField from "@components/inputs/TimePickerFormField";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onChange?: (value: RegMaintenancesItemResponseDto[]) => void;
  value?: RegMaintenancesItemResponseDto[];
  supervisionId?: string;
}

const RegMaintenancesFormField: FunctionComponent<PropsType> = (
  props: PropsType
) => {
  const { readOnly, value, onChange, supervisionId } = props;
  const { t } = useTranslation();

  const DATE_FORMAT = "HH:mm";

  const [regMaintenances, setRegMaintenances] = useState<
    RegMaintenancesItemResponseDto[] | undefined
  >(undefined);

  const [
    maintenanceModalVisible,
    setMaintenanceModalVisible,
  ] = useState<boolean>(false);

  const [regMaintenancesForm] = Form.useForm();

  const [regMaintenancesFields, setRegMaintenancesFields] = useState<Field[]>(
    []
  );

  useEffect(() => {
    if (regMaintenances === undefined) setRegMaintenances(value!);
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(regMaintenances!);
  }, [regMaintenances]);

  useEffect(() => {
    return requestCreateRegMaintenance.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMaintenanceModalVisible(false);
        setRegMaintenances((regMaintenances) =>
          regMaintenances ? [...regMaintenances, result.data!] : [result.data!]
        );
        clearMaintenanceForm();
        void message.success(t("regmaintenances.add.messages.success"));
      } else {
        void message.error(t("regmaintenances.add.messages.error"));
      }
    });
  });

  useEffect(() => {
    return requestUpdateRegMaintenance.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMaintenanceModalVisible(false);
        let index = regMaintenances?.findIndex(
          (item) => item.id === result.data?.id
        );
        if (index && index > -1) {
          var newMaintenances = regMaintenances?.slice();
          newMaintenances![index] = result.data;
          setRegMaintenances(newMaintenances);
        }
        clearMaintenanceForm();
        void message.success(t("regmaintenances.edit.messages.success"));
      } else {
        void message.error(t("regmaintenances.edit.messages.error"));
      }
    });
  });

  const handleCreateRegMaintenance = () => {
    let id = regMaintenancesForm.getFieldValue("id");
    const maintenanceToSave: RegMaintenancesSaveRequestDto = {
      supervisionId: supervisionId!,
      beginningTime: regMaintenancesForm.getFieldValue("beginningTime"),
      endingTime: regMaintenancesForm.getFieldValue("endingTime"),
      dayMonday: regMaintenancesForm.getFieldValue("dayMonday"),
      dayTuesday: regMaintenancesForm.getFieldValue("dayTuesday"),
      dayWednesday: regMaintenancesForm.getFieldValue("dayWednesday"),
      dayThursday: regMaintenancesForm.getFieldValue("dayThursday"),
      dayFriday: regMaintenancesForm.getFieldValue("dayFriday"),
      daySaturday: regMaintenancesForm.getFieldValue("daySaturday"),
      daySunday: regMaintenancesForm.getFieldValue("daySunday"),
      dayHoliday: regMaintenancesForm.getFieldValue("dayHoliday"),
    };
    if (id) {
      void requestUpdateRegMaintenance({
        id: id,
        dto: maintenanceToSave,
      });
    } else {
      void requestCreateRegMaintenance({
        dto: maintenanceToSave,
      });
    }
  };

  const clearMaintenanceForm = () => {
    setRegMaintenancesFields([
      { name: ["beginningTime"], value: undefined },
      { name: ["endingTime"], value: undefined },
      { name: ["id"], value: undefined },
      { name: ["dayMonday"], value: false },
      { name: ["dayTuesday"], value: false },
      { name: ["dayWednesday"], value: false },
      { name: ["dayThursday"], value: false },
      { name: ["dayFriday"], value: false },
      { name: ["daySaturday"], value: false },
      { name: ["daySunday"], value: false },
      { name: ["dayHoliday"], value: false },
    ]);
  };

  const showMaintenanceModal = () => {
    setMaintenanceModalVisible(true);
  };

  const handleCancelCreation = () => {
    setMaintenanceModalVisible(false);
    clearMaintenanceForm();
  };

  const handleDeleteMaintenance = (id: string) => {
    requestDeleteRegMaintenance({ id: id }).then((result) => {
      if (result.ok) {
        let index = regMaintenances?.findIndex((item) => item.id === id);
        if (index != undefined && index !== -1) {
          var newMaintenances = regMaintenances?.slice();
          newMaintenances!.splice(index, 1);
          setRegMaintenances(newMaintenances);
        }
        void message.success(t("regmaintenances.delete.messages.success"));
      } else {
        void message.error(t("regmaintenances.delete.messages.error"));
      }
    });
  };

  const columns: TableColumnType<RegMaintenancesItemResponseDto>[] = [
    {
      title: "",
      dataIndex: ["beginningTime", "endingTime"],
      render: (text: string, record: RegMaintenancesItemResponseDto) => {
        return (
          <span>
            {record.beginningTime} à {record.endingTime}
          </span>
        );
      },
    },
    {
      title: "L",
      dataIndex: "dayMonday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "M",
      dataIndex: "dayTuesday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "M",
      dataIndex: "dayWednesday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "J",
      dataIndex: "dayThursday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "V",
      dataIndex: "dayFriday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "S",
      dataIndex: "daySaturday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "D",
      dataIndex: "daySunday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
    },
    {
      title: "Férié",
      dataIndex: "dayHoliday",
      render: (text: boolean, record: RegMaintenancesItemResponseDto) => {
        return text && <FontAwesomeIcon icon={faCheck} color={"#219653"} />;
      },
      align: "center",
    },
  ];

  const actionColumn: TableColumnType<RegMaintenancesItemResponseDto> = {
    title: "Actions",
    key: "actions",
    render: (text: string, record: RegMaintenancesItemResponseDto) => (
      <div>
        <FontAwesomeIcon
          icon={faEdit}
          className={"action-button"}
          onClick={() => {
            regMaintenancesForm.setFields([
              { name: ["id"], value: record.id },
              { name: ["beginningTime"], value: record.beginningTime },
              { name: ["endingTime"], value: record.endingTime },
              { name: ["dayMonday"], value: record.dayMonday },
              { name: ["dayTuesday"], value: record.dayTuesday },
              { name: ["dayWednesday"], value: record.dayWednesday },
              { name: ["dayThursday"], value: record.dayThursday },
              { name: ["dayFriday"], value: record.dayFriday },
              { name: ["daySaturday"], value: record.daySaturday },
              { name: ["daySunday"], value: record.daySunday },
              { name: ["dayHoliday"], value: record.dayHoliday },
            ]);
            setMaintenanceModalVisible(true);
          }}
        />
        <FontAwesomeIcon
          icon={faTimes}
          className={"action-button"}
          onClick={() => {
            handleDeleteMaintenance(record.id);
          }}
        />
      </div>
    ),
  };

  return (
    <>
      {regMaintenances && regMaintenances.length ? (
        <Table
          pagination={false}
          scroll={{ x: true }}
          columns={readOnly ? columns : [...columns, actionColumn]}
          dataSource={regMaintenances}
        ></Table>
      ) : (
        t("regmaintenances.list.empty")
      )}
      {!readOnly && supervisionId && (
        <>
          <div
            className={
              "d-flex w-100 align-content-end flex-column my-2 text-truncate"
            }
          >
            <BasicButton
              variant={"clear"}
              text={t("regmaintenances.add.title")}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={showMaintenanceModal}
              iconLeft={false}
            />
          </div>
        </>
      )}
      <Form
        {...formItemBlockLayout}
        form={regMaintenancesForm}
        onFinish={handleCreateRegMaintenance}
        onFinishFailed={() => alert("something's wrong")} // TODO: do something with that
        fields={regMaintenancesFields}
      >
        <Modal
          title={t("regmaintenances.edit.modals.create.title")}
          visible={maintenanceModalVisible}
          onOk={handleCreateRegMaintenance}
          cancelText={"Annuler"}
          onCancel={handleCancelCreation}
        >
          <Form.Item name={"beginningTime"}>
            <TimePickerFormField
              showLabel
              module="regmaintenances.edit"
              field={"beginningTime"}
              required={true}
              format={DATE_FORMAT}
              showNow={false}
            />
          </Form.Item>
          <Form.Item name={"endingTime"}>
            <TimePickerFormField
              showLabel
              module="regmaintenances.edit"
              field={"endingTime"}
              required={true}
              format={DATE_FORMAT}
              showNow={false}
            />
          </Form.Item>
          {[
            "dayMonday",
            "dayTuesday",
            "dayWednesday",
            "dayThursday",
            "dayFriday",
            "daySaturday",
            "daySunday",
            "dayHoliday",
          ].map((day: string) => (
            <Row>
              <Col xs={24}>
                <Form.Item name={day}>
                  <CheckboxFormField
                    showLabel
                    module="regmaintenances.edit"
                    field={day}
                    required={true}
                    isChecked={false}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Modal>
      </Form>
    </>
  );
};

export default RegMaintenancesFormField;
