import { FunctionComponent, FormEvent, useState, useEffect } from "react";
import { ConfigProvider, List, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { SupervisionNodeItemResponseDto } from "@state/supervisionnodes/dto/response/supervisionnode.item.response.dto";
import { requestGetSupervisionNodes } from "@state/supervisionnodes/SupervisionNodeEffects";
import { SupervisionNodeCurrentStatus } from "@state/supervisions/dto/response/supervision.details.response.dto";
import { getSupervisionColor } from "@utils/enums/status.enum";
import { FrownOutlined } from "@ant-design/icons";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onChange?: (value: SupervisionNodeCurrentStatus[]) => void;
  value?: SupervisionNodeCurrentStatus[];
}

const RecipientsFormField: FunctionComponent<PropsType> = (
  props: PropsType
) => {
  const { readOnly, value, onChange } = props;
  const { t } = useTranslation();

  const [options, setOptions] = useState<SupervisionNodeItemResponseDto[]>([]);

  const [availableOptions, setAvailableOptions] = useState<
    SupervisionNodeItemResponseDto[] | undefined
  >([]);

  // const [nodes, setNodes] = useState<SupervisionNodeItemResponseDto[]>([]);

  const [currentStatus, setCurrentStatus] = useState<
    SupervisionNodeCurrentStatus[]
  >([]);

  useEffect(() => {
    // let nodes = value!.map(i => i.supervisionNodeId);
    // setNodes(nodes);
    setCurrentStatus(value!);
  }, [value]);

  useEffect(() => {
    computeAvailableOptions();
  }, [currentStatus, options]);

  useEffect(() => {
    return requestGetSupervisionNodes.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setOptions(result.data);
        computeAvailableOptions();
      } else {
        void message.error(t("supervisionnodes.notFound"));
      }
    });
  });

  const computeAvailableOptions = () => {
    let nodes = currentStatus?.map((i) => i.supervisionNodeId);
    let available = nodes
      ? options.filter(
          (item) => !nodes.filter((rec) => rec.id === item.id).length
        )
      : options;
    setAvailableOptions(available);
  };

  useEffect(() => {
    requestGetSupervisionNodes({});
  }, []);

  const handleSelect = (val: string) => {
    let node = options?.find((item) => item.id === val);
    let newCurrentStatus = {
      id: "0",
      supervisionNodeId: node as SupervisionNodeItemResponseDto,
      status: "INIT",
      errorCode: "null",
      exceptionStackTrace: "null",
    } as SupervisionNodeCurrentStatus;
    var newArr = currentStatus?.slice() ?? [];
    newArr.push(newCurrentStatus);
    setCurrentStatus(newArr);
    if (onChange) onChange(newArr);
  };

  const handleDeselect = (val: string) => {
    let newArr = currentStatus?.filter(
      (item) => item.supervisionNodeId.id !== val
    );
    setCurrentStatus(newArr);
    if (onChange) onChange(newArr);
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <FrownOutlined style={{ fontSize: 20 }} />
      <p>{t("supervisionnodes.list.empty")}</p>
    </div>
  );

  return (
    <>
      {currentStatus && currentStatus.length ? (
        <List
          dataSource={currentStatus}
          renderItem={(item: SupervisionNodeCurrentStatus) => (
            <List.Item
              actions={
                !readOnly
                  ? [
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={"action-button"}
                        onClick={() => {
                          handleDeselect(item.supervisionNodeId.id);
                        }}
                      />,
                    ]
                  : []
              }
            >
              {
                <div
                  key={item.supervisionNodeId.id}
                  className={`w-100 p-1 rounded status-${getSupervisionColor(
                    item.status
                  )} d-flex justify-content-between`}
                >
                  <span className={"font-weight-bold"}>
                    {item.supervisionNodeId.name}
                  </span>
                  <span>{item.supervisionNodeId.hostname}</span>
                </div>
              }
            </List.Item>
          )}
        />
      ) : (
        t("supervisionnodes.edit.empty")
      )}
      {!readOnly && availableOptions && availableOptions.length > 0 && (
        <>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Select
              mode={"multiple"}
              style={{ width: "100%" }}
              showArrow={true}
              placeholder={t("supervisionnodes.add.title")}
              value={[]}
              disabled={readOnly}
              options={
                availableOptions?.map((item) => {
                  return { value: item.id, label: item.name };
                }) ?? []
              }
              onSelect={handleSelect}
              onDeselect={handleDeselect}
              filterOption={(inputValue, option) =>
                option?.label
                  ? option?.label
                      .toString()
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  : false
              }
            />
          </ConfigProvider>
        </>
      )}
    </>
  );
};

export default RecipientsFormField;
