import {
  restCreationHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restPatchHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { UserUpdateStatusRequestDto } from "@state/users/dto/request/user.update.status.request.dto";
import { UserDetailsResponseDto } from "@state/users/dto/response/user.details.response.dto";
import { UserSaveRequestDto } from "@state/users/dto/request/user.save.request.dto";

const baseUrl = configuration.backendBaseUrl;
const usersUrl = baseUrl + "/users/";

export const requestGetUsers = createEffect({
  handler: restListHandlerPagination<Pageable<UserItemResponseDto>>(usersUrl),
});

export const requestGetUser = createEffect({
  handler: restDetailsHandler<UserDetailsResponseDto>(usersUrl),
});

export const requestCreateUser = createEffect({
  handler: restCreationHandler<UserSaveRequestDto, UserDetailsResponseDto>(
    usersUrl
  ),
});

export const requestUpdateUser = createEffect({
  handler: restUpdateHandler<UserSaveRequestDto, UserDetailsResponseDto>(
    usersUrl
  ),
});

export const requestUpdateUserStatus = createEffect({
  handler: restPatchHandler<UserUpdateStatusRequestDto, { status: string }>(
    usersUrl
  ),
});
