import { FunctionComponent, useState } from "react";
import ClientForm from "./ClientForm";
import PageBody from "@components/bodies/PageBody";
import PageLayout from "@components/layouts/PageLayout";
import { RouteComponentProps, useParams } from "react-router-dom";

const ClientScreen: FunctionComponent<RouteComponentProps> = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout>
      <>
        <PageBody>
          <ClientForm
            clientId={clientId ? clientId : ""}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default ClientScreen;
