import { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import SupervisionNodeForm from "@views/supervisionnodes/details/SupervisionNodeForm";

const SupervisionNodeAddScreen: FunctionComponent<RouteComponentProps> = () => {
  return (
    <PageLayout>
      <>
        <PageBody>
          <SupervisionNodeForm editMode />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default SupervisionNodeAddScreen;
