declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/naming-convention */
    REACT_APP_BACKEND_BASE_URL?: string;
    REACT_APP_FRONTEND_BASE_URL?: string;
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}

interface ConfigInterface {
  backendBaseUrl: string;
  frontendBaseUrl: string;
}

const configuration: ConfigInterface = {
  backendBaseUrl:
    process.env.REACT_APP_BACKEND_BASE_URL ||
    window.REACT_APP_BACKEND_BASE_URL ||
    "MISSING_REACT_APP_BACKEND_BASE_URL",
  frontendBaseUrl:
    process.env.REACT_APP_FRONTEND_BASE_URL ||
    window.REACT_APP_FRONTEND_BASE_URL ||
    "MISSING_REACT_APP_FRONTEND_BASE_URL",
};

export default configuration;
