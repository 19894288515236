import { createEvent } from "effector";
import { AuthentifiedContext, AuthentifiedUser } from "@type/auth/auth.types";

export const authentifiedContextEvent = createEvent<
  AuthentifiedContext | undefined
>();

export const login = createEvent<void>();

export const setUser = createEvent<AuthentifiedUser>("SET_USER");

export const logout = createEvent<void>();
