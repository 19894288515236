import i18n from "i18next";
import { ClientItemResponseDto } from "@state/clients/dto/response/client.item.response.dto";
import { ColumnsType } from "antd/lib/table/interface";

export const columns = (): ColumnsType<ClientItemResponseDto> => [
  {
    title: i18n.t("clients.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t("clients.list.columns.updatedAt"),
    key: "updatedAt",
    dataIndex: "updatedAt",
    ellipsis: false,
  },
];
