import { FunctionComponent, ReactNode } from "react";
import { Card } from "antd";

interface CardContainerProps {
  children: ReactNode;
  title?: string;
  className?: string | null;
}

const CardContainer: FunctionComponent<CardContainerProps> = (
  props: CardContainerProps
) => {
  const { children, title, className } = props;

  return (
    <Card
      title={title}
      className={`shadow-sm rounded-1 my-4 ${className ? className : ""}`}
      bordered={false}
      headStyle={{ fontWeight: "bold", fontSize: "1.6em", border: "none" }}
    >
      {children}
    </Card>
  );
};

export default CardContainer;
