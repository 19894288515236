import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { emptySearchResult } from "@utils/Constant";
import { Pageable } from "@type/pagination/pagination.types";
import { SupervisionNodeItemResponseDto } from "@state/supervisionnodes/dto/response/supervisionnode.item.response.dto";
import { useTranslation } from "react-i18next";
import { message, Table } from "antd";
import { requestGetSupervisionNodesPage } from "@state/supervisionnodes/SupervisionNodeEffects";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@components/lists/SupervisionNodesColumns";
import { RouteComponentProps, useHistory } from "react-router-dom";
import BasicButton from "@components/buttons/BasicButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { hasPermission } from "@utils/permission-helper";

const SupervisionNodesScreen: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(3);

  const [supervisionNodes, setSupervisionNodes] = useState<
    Pageable<SupervisionNodeItemResponseDto>
  >(emptySearchResult);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const fetchSupervisionNodes = useCallback(() => {
    setDataHasLoaded(false);
    requestGetSupervisionNodesPage({
      page: pageNumber - 1,
      limit,
    })
      .catch(() => {
        void message.error(t("supervisionNodes.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t]);

  useEffect(() => {
    fetchSupervisionNodes();
  }, [fetchSupervisionNodes]);

  useEffect(() => {
    return requestGetSupervisionNodesPage.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setSupervisionNodes(result.data);
      }
    });
  });

  return (
    <PageLayout>
      <>
        <PageBody>
          <div className="d-flex flex-column align-items-stretch flex-sm-row">
            <div className="w-100 d-flex justify-content-between mb-3 flex-column flex-sm-row">
              <h4 className="text-primary">
                {t("supervisionnodes.list.title")}
              </h4>
              {hasPermission("supervisions", true) && (
                <BasicButton
                  variant={"default"}
                  text={t("supervisionnodes.actions.add")}
                  onClick={() => history.push("/supervisionnodes/add")}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  iconLeft={false}
                />
              )}
            </div>
          </div>
          <Table
            style={{ whiteSpace: "break-spaces" }}
            locale={{ emptyText: dataHasLoaded ? "" : " " }}
            loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
            columns={columns()}
            dataSource={supervisionNodes.content}
            pagination={{
              total: supervisionNodes.totalElements,
              pageSize: supervisionNodes.size,
              current: supervisionNodes.number + 1,
              onChange: setPageNumber,
              onShowSizeChange: handleChangeLimit,
            }}
            showSorterTooltip={false}
            rowKey={(record) => record.id}
            rowClassName="cursor-pointer"
            onRow={(record) => {
              return {
                onClick: () => history.push(`/supervisionnodes/${record.id}`),
              };
            }}
          />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default SupervisionNodesScreen;
