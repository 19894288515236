import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restPatchHandler,
  restPostHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { SupervisionUpdateStatusRequestDto } from "@state/supervisions/dto/request/supervision.update.status.request.dto";
import {
  SupervisionDetailsResponseDto,
  SupervisionNodeCurrentStatus,
} from "@state/supervisions/dto/response/supervision.details.response.dto";
import { SupervisionSaveRequestDto } from "@state/supervisions/dto/request/supervision.save.request.dto";
import { SupervisionItemResponseDto } from "@state/supervisions/dto/response/supervision.item.response.dto";
import { SupervisionTestResponseDto } from "@state/supervisions/dto/response/supervision.test.response.dto";
import { RecipientItemResponseDto } from "@state/recipients/dto/response/recipient.item.response.dto";

const baseUrl = configuration.backendBaseUrl;
const supervisionsUrl = baseUrl + "/supervisions/";

export const requestGetSupervisions = createEffect({
  handler: restListHandler<SupervisionItemResponseDto>(supervisionsUrl),
});

export const requestGetSupervision = createEffect({
  handler: restDetailsHandler<SupervisionDetailsResponseDto>(supervisionsUrl),
});

export const requestGetParentSupervision = createEffect({
  handler: restDetailsHandler<SupervisionDetailsResponseDto>(supervisionsUrl),
});

export const requestTestSupervisionConfiguration = createEffect({
  handler: restPostHandler<{ details: string }, SupervisionTestResponseDto>(
    supervisionsUrl + "test"
  ),
});

export const requestCreateSupervision = createEffect({
  handler: restCreationHandler<
    SupervisionSaveRequestDto,
    SupervisionDetailsResponseDto
  >(supervisionsUrl),
});

export const requestUpdateSupervision = createEffect({
  handler: restUpdateHandler<
    SupervisionSaveRequestDto,
    SupervisionDetailsResponseDto
  >(supervisionsUrl),
});

export const requestUpdateSupervisionStatus = createEffect({
  handler: restPatchHandler<
    SupervisionUpdateStatusRequestDto,
    { status: string }
  >(supervisionsUrl),
});

export const requestDeleteSupervision = createEffect({
  handler: restDeletionHandler(supervisionsUrl),
});

export const requestUpdateSupervisionField = (
  id: string,
  field: string,
  value: string
) => {
  return new Promise<void>((resolve, reject) => {
    requestGetSupervision(id).then((result) => {
      if (result.ok && result.data) {
        var supervisionToSave: SupervisionSaveRequestDto = {
          name: result.data.name as string,
          parentSupervisionId: result.data.parentSupervisionId as string,
          comments: result.data.comments as string,
          details: result.data.details,
          active: !["DISABLED", "DISABLED_PARENT"].includes(
            result.data.status
          ) as boolean,
          clientId: result.data.clientId as string,
          muteAlert: result.data.muteAlert as boolean,
          downCyclesBeforeEmail: result.data.downCyclesBeforeEmail as number,
          recipients: result.data.recipients?.map(
            (item: RecipientItemResponseDto) => {
              return { id: item.id };
            }
          ),
          supervisionNodes: result.data.nodeSupervisionCurrentStatus?.map(
            (item: SupervisionNodeCurrentStatus) => {
              return { id: item.supervisionNodeId.id };
            }
          ),
        };

        // Update value
        (supervisionToSave as any)[field] = value;

        requestUpdateSupervision({
          id: id,
          dto: supervisionToSave,
        }).then((result) => {
          result.ok ? resolve() : reject();
        });
      } else {
        return reject();
      }
    });
  });
};

export const requestDuplicateSupervision = (id: string, newName: string) => {
  return new Promise<SupervisionDetailsResponseDto>((resolve, reject) => {
    requestGetSupervision(id).then((result) => {
      if (result.ok && result.data) {
        var supervisionToSave: SupervisionSaveRequestDto = {
          name: newName,
          parentSupervisionId: result.data.parentSupervisionId as string,
          comments: result.data.comments as string,
          details: result.data.details,
          active: !["DISABLED", "DISABLED_PARENT"].includes(
            result.data.status
          ) as boolean,
          clientId: result.data.clientId as string,
          muteAlert: result.data.muteAlert as boolean,
          downCyclesBeforeEmail: result.data.downCyclesBeforeEmail as number,
          recipients: result.data.recipients?.map(
            (item: RecipientItemResponseDto) => {
              return { id: item.id };
            }
          ),
          supervisionNodes: result.data.nodeSupervisionCurrentStatus?.map(
            (item: SupervisionNodeCurrentStatus) => {
              return { id: item.supervisionNodeId.id };
            }
          ),
        };

        requestCreateSupervision({
          dto: supervisionToSave,
        }).then((result) => {
          result.ok && result.data ? resolve(result.data) : reject();
        });
      } else {
        return reject();
      }
    });
  });
};
