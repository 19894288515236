import { useEffect, useState, FunctionComponent, useCallback } from "react";
import {
  Checkbox,
  Col,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Field } from "@type/form/field.types";
import {
  AssociatedClient,
  UserDetailsResponseDto,
} from "@state/users/dto/response/user.details.response.dto";
import {
  requestCreateUser,
  requestGetUser,
  requestUpdateUser,
  requestUpdateUserStatus,
} from "@state/users/UserEffects";
import { FIELD_LENGTH, formItemBlockLayout } from "@utils/Constant";
import { UserSaveRequestDto } from "@state/users/dto/request/user.save.request.dto";
import InputFormField from "@components/inputs/InputFormField";
import { ValidateErrorEntity, Store } from "rc-field-form/lib/interface";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { PROFILES_OPTIONS, getProfileLabel } from "@utils/enums/profile.enum";
import BasicButton from "@components/buttons/BasicButton";
import { requestGetClients } from "@state/clients/ClientEffects";
import { ClientItemResponseDto } from "@state/clients/dto/response/client.item.response.dto";

const { confirm } = Modal;

export interface Props {
  userId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const UserForm: FunctionComponent<Props> = (props: Props) => {
  const { userId, editMode, setEditMode } = props;
  const { t } = useTranslation();

  const history = useHistory();

  const [form] = Form.useForm();

  const [user, setUser] = useState<UserDetailsResponseDto | null>(null);

  const [fields, setFields] = useState<Field[]>([]);

  const [clients, setClients] = useState<ClientItemResponseDto[]>([]);

  const [associatedClients, setAssociatedClients] = useState<
    AssociatedClient[]
  >([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] = useState<boolean>(
    false
  );

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      const firstNameField = form.getFieldValue("firstName") as string;
      const lastNameField = form.getFieldValue("lastName") as string;
      const profileField = form.getFieldValue("profile") as string[];
      const profileTranslated = profileField.map(
        (profile) => " " + t(getProfileLabel(profile))
      );

      confirm({
        title: t("users.edit.modals.save.title"),
        content: t("users.edit.modals.save.content", {
          user: `${firstNameField} ${lastNameField}`,
          profile: profileTranslated,
          associatedClient: associatedClients,
        }),
        okText: t("buttons.yes"),
        cancelText: t("buttons.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  useEffect(() => {
    if (userId) {
      void requestGetUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    return requestGetUser.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        setUser(result.data);
        setAssociatedClients(result.data.associatedClient);
        setFields([
          {
            name: ["firstName"],
            value: result.data.firstName,
          },
          {
            name: ["lastName"],
            value: result.data.lastName,
          },
          {
            name: ["email"],
            value: result.data.email,
          },
          {
            name: ["profile"],
            value: result.data.profile,
          },
          {
            name: ["clientId"],
            value: associatedClients.map((item) => item.clientId),
          },
          {
            name: ["associatedClient"],
            value: result.data.associatedClient.map((item) => item.clientId),
          },
        ]);
      } else {
        void message.error(t("users.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestCreateUser.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        void message.success(t("users.add.messages.success"));
        history.push("/users");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateUser.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        setUser(result.data);
        void message.success(t("users.edit.messages.success"));
        history.push("/users");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateUserStatus.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data && user) {
        void message.success(t("editUser.status"));
        const newUser = {
          ...user,
          status: result.data.status,
        };

        setUser(newUser);
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  const fetchUsers = useCallback(() => {
    requestGetClients({}).catch(() => {
      void message.error(t("users.list.messages.loading-error"));
    });
  }, [t]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    return requestGetClients.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setClients(result.data);
      }
    });
  });

  const handleSubmit = (values: Store) => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setButtonLoading(true);
        const userToSave: UserSaveRequestDto = {
          firstName: values.firstName as string,
          lastName: values.lastName as string,
          email: values.email as string,
          profile: values.profile as string[],
          associatedClient: associatedClients as AssociatedClient[],
        };
        if (userId) {
          void requestUpdateUser({
            id: userId,
            dto: userToSave,
          });
        } else {
          void requestCreateUser({
            dto: userToSave,
          });
        }
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    void message.error(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <>
      {!getResponseReceived && userId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !userId || user ? (
        <>
          <Form
            {...formItemBlockLayout}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            fields={fields}
          >
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex justify-content-between mb-3 flex-column flex-sm-row">
                <h4 className="text-primary">{t("users.title")}</h4>
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  {editMode && setEditMode && (
                    <Popconfirm
                      title={t("forms.actions.modals.cancel.content")}
                      okText={t("buttons.yes")}
                      cancelText={t("buttons.no")}
                      onConfirm={() => {
                        setEditMode(false);
                      }}
                      placement="top"
                      icon={<QuestionCircleOutlined />}
                      className="m-2"
                    >
                      <BasicButton
                        type={"reset"}
                        variant={"default"}
                        text={t("buttons.cancel")}
                        icon={<StopOutlined />}
                        iconLeft={false}
                      />
                    </Popconfirm>
                  )}
                  {editMode ? (
                    <BasicButton
                      variant={"primary"}
                      text={t("buttons.save")}
                      onClick={form.submit}
                      icon={<SaveOutlined />}
                      iconLeft={false}
                      isLoading={buttonLoading}
                    />
                  ) : (
                    user && (
                      <>
                        {setEditMode && (
                          <BasicButton
                            type={"reset"}
                            variant={"primary"}
                            text={t("buttons.edit")}
                            onClick={() => setEditMode(true)}
                            icon={<EditOutlined />}
                            iconLeft={false}
                            isLoading={buttonLoading}
                          />
                        )}
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
            <Row>
              <Col xs={24}>
                <InputFormField
                  showLabel
                  module="users.edit"
                  field="firstName"
                  required={true}
                  emptyErrorMessage={t("forms.errors.mandatory")}
                  readOnly={true}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
              <Col xs={24}>
                <InputFormField
                  showLabel
                  module="users.edit"
                  field="lastName"
                  required={true}
                  emptyErrorMessage={t("forms.errors.mandatory")}
                  readOnly={true}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <InputFormField
                  showLabel
                  type="email"
                  module="users.edit"
                  field="email"
                  required={true}
                  emptyErrorMessage={t("forms.errors.mandatory")}
                  readOnly={true}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <SelectFormField
                  showLabel
                  mode="multiple"
                  module="users.edit"
                  field="profile"
                  required={true}
                  readOnly={!editMode}
                  options={PROFILES_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <SelectFormField
                  showLabel
                  mode="multiple"
                  module="users.edit"
                  onSelect={(id) => {
                    let clients = [...associatedClients];
                    clients.push({ clientId: id, writable: false });
                    setAssociatedClients(clients);
                  }}
                  onDeselect={(id) => {
                    let index = associatedClients.findIndex(
                      (item) => item.clientId == id
                    );
                    let clients = [...associatedClients];
                    clients.splice(index, 1);
                    setAssociatedClients(clients);
                  }}
                  field="associatedClient"
                  readOnly={!editMode}
                  options={clients.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {associatedClients.length ? (
                  <>
                    <br />
                    <span>
                      <b>Droits d'écriture</b>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {associatedClients.map((client: AssociatedClient) => {
                  return (
                    <div key={client.clientId}>
                      <Checkbox
                        checked={client.writable}
                        disabled={!editMode}
                        onChange={(e) => {
                          let index = associatedClients.findIndex(
                            (item) => item.clientId === client.clientId
                          );
                          let clients = [...associatedClients];
                          let updatedClient = { ...clients[index] };
                          updatedClient.writable = e.target.checked;
                          clients[index] = updatedClient;
                          setAssociatedClients(clients);
                        }}
                      >
                        {clients.length
                          ? clients.find((item) => item.id === client.clientId)
                              ?.name
                          : ""}
                      </Checkbox>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <div>{t("users.notFound")}</div>
      )}
    </>
  );
};
export default UserForm;
