import i18n from "i18next";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { ColumnsType } from "antd/lib/table/interface";

// const popoverContent = (record: UserItemResponseDto) => (
//   <div className="d-inline-flex">
//     {record.status !== "active" ? (
//       <TableButton
//         icon={<CheckOutlined />}
//         tooltipTitle={i18n.t("users.list.actions.enable")}
//         onClick={() => {
//           showConfirm(
//             i18n.t("users.edit.modals.enable.title", {
//               user: record.name,
//             }),
//             () => {
//               void requestUpdateUserStatus({
//                 id: record.id,
//                 dto: {
//                   status: "active",
//                 },
//               });
//             },
//           );
//         }}
//         buttonClassName="btn-primary mr-1 btn-list"
//       />
//     ) : (
//       <TableButton
//         icon={<CloseOutlined />}
//         tooltipTitle={i18n.t("users.list.actions.disable")}
//         onClick={() => {
//           showConfirm(
//             i18n.t("users.edit.modals.disable.title", {
//               user: record.name,
//             }),
//             () => {
//               void requestUpdateUserStatus({
//                 id: record.id,
//                 dto: {
//                   status: "disabled",
//                 },
//               });
//             },
//           );
//         }}
//         buttonClassName="btn-primary mr-1 btn-list"
//       />
//     )}
//   </div>
// );

export const columns = (): ColumnsType<UserItemResponseDto> => [
  {
    title: i18n.t("users.list.columns.firstName"),
    key: "firstName",
    dataIndex: "firstName",
    ellipsis: false,
  },
  {
    title: i18n.t("users.list.columns.lastName"),
    key: "lastName",
    dataIndex: "lastName",
    ellipsis: false,
  },
  {
    title: i18n.t("users.list.columns.email"),
    key: "email",
    dataIndex: "email",
    ellipsis: false,
  },
  {
    title: i18n.t("users.list.columns.profile"),
    key: "profile",
    dataIndex: "profile",
    ellipsis: false,
    render: (status: string[]) =>
      status.map((item) => i18n.t(`users.labels.profiles.${item}`)).join(", "),
  },
  // {
  //   title: i18n.t("users.list.columns.actions"),
  //   key: "actions",
  //   fixed: alignRight,
  //   className: "it-actions",
  //   onCell: () => {
  //     return {
  //       onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
  //         e.stopPropagation(),
  //     };
  //   },
  //   render: (record: UserItemResponseDto) => (
  //     <Popover
  //       content={popoverContent(record)}
  //       placement="bottomRight"
  //       trigger="hover"
  //     >
  //       <Button
  //         icon={<MoreOutlined />}
  //         type="text"
  //         size="large"
  //         className="btn-list"
  //       />
  //     </Popover>
  //   ),
  // },
];
