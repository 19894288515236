import {
  ServerResponseParser,
  ParsedResponse,
} from "@utils/rest/ServerResponseParse";
import { logout } from "@state/auth/AuthEvents";
import logger from "@utils/logger";
import { DEFAULT_500_ERROR } from "@utils/Constant";

type RestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface DataWithId {
  id: string;
}

interface SearchDto {
  [index: string]: any;
}

const executeCall = <U>(
  fetchPromise: Promise<Response>,
  method: RestMethod
) => {
  return fetchPromise
    .then((response: Response) => {
      logger.debug(`HTTP response ${response.status}`);
      if (response.status === 401) {
        logout();
      }
      if (response.status === 500) {
        return DEFAULT_500_ERROR;
      }
      return method === "DELETE"
        ? {
            responseCode: response.status,
            errorMessage: undefined,
            data: undefined,
            ok: true,
          }
        : new ServerResponseParser<U>().parseResponse(response);
    })
    .catch(() => {
      return DEFAULT_500_ERROR;
    });
};

export const makeRestCall = <T, U>(
  url: string,
  method: RestMethod,
  data?: T
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => controller.abort(), 15000);

  const adalToken = localStorage.getItem("adal.idtoken");

  const headers: HeadersInit = adalToken
    ? {
        Authorization: `Bearer ${String(adalToken)}`,
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
      };

  const fetchPromise = fetch(url, {
    method,
    signal,
    body: data ? JSON.stringify(data) : undefined,
    headers,
  });
  return executeCall<U>(fetchPromise, method);
};

const buildSearchParameters = <T extends SearchDto>(dto?: T) => {
  return dto
    ? Object.keys(dto)
        .map((key) =>
          dto[key] !== undefined ? `&${key}=${String(dto[key])}` : ""
        )
        .join("")
    : "";
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandlerPagination = <T, U extends SearchDto = {}>(
  endpointUrl: string
): ((data: {
  page: number;
  limit: number;
  dto?: U;
}) => Promise<ParsedResponse<T>>) => {
  return ({ page, limit, dto }: { page: number; limit: number; dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    return makeRestCall<void, T>(
      `${endpointUrl + "page"}?page=${page}&size=${limit}${searchParameters}`,
      "GET",
      undefined
    );
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandler = <T, U extends SearchDto = {}>(
  endpointUrl: string
): ((data: { dto?: U }) => Promise<ParsedResponse<T[]>>) => {
  return ({ dto }: { dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    return makeRestCall<void, T[]>(
      `${endpointUrl + "list/"}?${searchParameters}`,
      "GET",
      undefined
    );
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandlerFilteredBy = <T, U extends SearchDto = {}>(
  endpointUrl: string
): ((data: { dto?: U; filter: string }) => Promise<ParsedResponse<T[]>>) => {
  return ({ dto, filter }: { dto?: U; filter?: string }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    return makeRestCall<void, T[]>(
      `${endpointUrl + "list/"}${filter}?${searchParameters}`,
      "GET",
      undefined
    );
  };
};

export const restDetailsHandler = <T>(
  endpointUrl: string,
  suffixUrl?: string
): ((id: string) => Promise<ParsedResponse<T>>) => {
  return (id: string) =>
    makeRestCall<void, T>(
      endpointUrl + id + (suffixUrl ? suffixUrl : ""),
      "GET"
    );
};

export const restGetUniqueHandler = <T>(
  endpointUrl: string
): (() => Promise<ParsedResponse<T>>) => {
  return () => makeRestCall<void, T>(endpointUrl, "GET");
};

export const restIdListHandler = <T>(
  endpointUrl: string
): ((id: string) => Promise<ParsedResponse<T[]>>) => {
  return (id: string) => makeRestCall<void, T[]>(endpointUrl + id, "GET");
};

export const restCreationHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
  suffix2Url?: string
): ((data: {
  upperEntityId?: string;
  upperEntity2Id?: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; upperEntity2Id?: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        (data.upperEntity2Id ? data.upperEntity2Id : "") +
        (suffix2Url ? suffix2Url : ""),
      "POST",
      data.dto
    );
};

export const restDeletionHandler = <T extends DataWithId>(
  endpointUrl: string
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(endpointUrl + data.id, "DELETE", data);
};

export const restUpdateHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        data.id,
      "PUT",
      data.dto
    );
};

export const restPatchHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        data.id,
      "PATCH",
      data.dto
    );
};

export const restPostHandler = <T, U>(
  endpointUrl: string
): ((data: { dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { dto: T }) =>
    makeRestCall<T, U>(endpointUrl, "POST", data.dto);
};

export const restPostHandlerWithSuffix = <T, U>(
  endpointUrl: string
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, U>(endpointUrl + data.id, "POST", data.dto);
};

export const restWorkflowHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        data.id +
        "/workflow",
      "POST",
      data.dto
    );
};

export const restFieldUpdateHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl + data.id + (suffixUrl ? suffixUrl : ""),
      "PATCH",
      data.dto
    );
};

export const restIncreaseOrderHandler = <T extends DataWithId>(
  endpointUrl: string
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(endpointUrl + data.id + "/increaseOrder", "POST");
};

export const restDecreaseOrderHandler = <T extends DataWithId>(
  endpointUrl: string
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(endpointUrl + data.id + "/decreaseOrder", "POST");
};
