import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restPostHandlerWithSuffix,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { InterruptionHistoryGlobalGetRequestDto } from "@state/interruptionHistoryGlobal/dto/request/interruptionHistoryGlobal.get.request.dto";
import { InterruptionHistoryGlobalItemResponseDto } from "@state/interruptionHistoryGlobal/dto/response/interruptionHistoryGlobal.item.response.dto";
import { InterruptionHistoryGlobalUpdateRequestDto } from "@state/interruptionHistoryGlobal/dto/request/interruptionHistoryGlobal.update.request.dto";

const baseUrl = configuration.backendBaseUrl;
const interruptionHistoryUrlBySupervision =
  baseUrl + "/interruptionhistoryglobal/supervision/";
const interruptionHistoryUrl = baseUrl + "/interruptionhistoryglobal/";

export const requestPostInterruptionHistories = createEffect({
  handler: restPostHandlerWithSuffix<
    InterruptionHistoryGlobalGetRequestDto,
    InterruptionHistoryGlobalItemResponseDto[]
  >(interruptionHistoryUrlBySupervision),
});

export const requestUpdateInterruptionHistories = createEffect({
  handler: restUpdateHandler<
    InterruptionHistoryGlobalUpdateRequestDto,
    InterruptionHistoryGlobalItemResponseDto
  >(interruptionHistoryUrl),
});
