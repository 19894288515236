import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons/lib";

const { confirm } = Modal;

export function showConfirm(
  title: string,
  onOk?: () => void,
  onCancel?: () => void
): void {
  confirm({
    title: title,
    icon: <QuestionCircleOutlined />,
    okText: "Confirmer",
    cancelText: "Annuler",
    centered: true,
    onOk() {
      onOk && onOk();
    },
    onCancel() {
      onCancel && onCancel();
    },
  });
}
