export enum STATUS {
  "UP" = "UP",
  "DOWN" = "DOWN",
  "PARTIAL-DOWN" = "PARTIAL-DOWN",
  "DISABLED" = "DISABLED",
  "DISABLED_PARENT" = "DISABLED_PARENT",
  "MAINTENANCE_TEMP" = "MAINTENANCE_TEMP",
  "MAINTENANCE_REG" = "MAINTENANCE_REG",
  "PARENT_DOWN" = "PARENT_DOWN",
  "INIT" = "INIT",
}

export const getSupervisionColor = (status: string) => {
  switch (status) {
    case STATUS.UP:
      return "up";
    case STATUS.DOWN:
    case STATUS.PARENT_DOWN:
      return "down";
    case STATUS["PARTIAL-DOWN"]:
      return "partial-down";
    case STATUS.DISABLED:
    case STATUS.DISABLED_PARENT:
      return "disabled";
    case STATUS.MAINTENANCE_REG:
    case STATUS.MAINTENANCE_TEMP:
      return "maintenance";
    default:
      return "default";
  }
};
