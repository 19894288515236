import { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Field } from "@type/form/field.types";
import {
  SupervisionDetails,
  SupervisionDetailsResponseDto,
  SupervisionNodeCurrentStatus,
} from "@state/supervisions/dto/response/supervision.details.response.dto";
import {
  requestCreateSupervision,
  requestGetParentSupervision,
  requestGetSupervision,
  requestTestSupervisionConfiguration,
  requestUpdateSupervision,
  requestUpdateSupervisionStatus,
} from "@state/supervisions/SupervisionEffects";
import { SUPERVISIONS_ROUTE } from "@utils/Routes";
import { FIELD_LENGTH, formItemBlockLayout } from "@utils/Constant";
import { SupervisionSaveRequestDto } from "@state/supervisions/dto/request/supervision.save.request.dto";
import InputFormField from "@components/inputs/InputFormField";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { requestGetRecipients } from "@state/recipients/RecipientEffects";
import { requestGetClients } from "@state/clients/ClientEffects";
import { ClientItemResponseDto } from "@state/clients/dto/response/client.item.response.dto";
import { hasPermission } from "@utils/permission-helper";
import { RecipientItemResponseDto } from "@state/recipients/dto/response/recipient.item.response.dto";
import { requestGetRegMaintenancesForSupervision } from "@state/regmaintenances/RegMaintenancesEffects";
import { requestGetTempMaintenancesForSupervision } from "@state/tempmaintenances/TempMaintenancesEffects";
import RegMaintenancesFormField from "@components/inputs/RegMaintenancesFormField";
import RecipientsFormField from "@components/inputs/RecipientsFormField";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import TempMaintenancesFormField from "@components/inputs/TempMaintenancesFormField";
import CardContainer from "@components/containers/CardContainer";
import NodesFormField from "@components/inputs/NodesFormField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faPlus } from "@fortawesome/free-solid-svg-icons";
import BasicButton from "@components/buttons/BasicButton";
import { Rule } from "antd/es/form";

const { confirm } = Modal;

export interface Props {
  supervisionId?: string;
  parentId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

interface SupervisionTypeFields {
  name: string;
  type: string;
  required: boolean;
  rules: Rule[];
}
const SupervisionForm: FunctionComponent<Props> = (props: Props) => {
  const { supervisionId, editMode, setEditMode, parentId } = props;
  const { t } = useTranslation();

  const history = useHistory();

  const [form] = Form.useForm();

  const [
    supervision,
    setSupervision,
  ] = useState<SupervisionDetailsResponseDto | null>(null);

  const [clients, setClients] = useState<ClientItemResponseDto[]>([]);

  const [parentClient, setParentClient] = useState<string>("");

  const [parentSupervision, setParentSupervision] = useState<string | null>("");

  const [type, setType] = useState<string>("");

  const [fields, setFields] = useState<Field[]>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] = useState<boolean>(
    false
  );

  const [clientCanEdit, setClientCanEdit] = useState<boolean>(true);

  const [abstract, setAbstract] = useState<boolean>();

  const hasWritePermission = hasPermission("supervisions", true);

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const checkTimeout = (_: any, value: number) => {
    if (value >= 1) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("supervisions.test.check.timeout")));
  };

  const detailsColumns: { [id: string]: SupervisionTypeFields[] } = {
    PING: [
      {
        name: "hostname",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "timeout",
        type: "input",
        required: true,
        rules: [
          {
            validator: checkTimeout,
          },
        ],
      },
    ],
    PORT: [
      {
        name: "hostname",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "port",
        type: "input",
        required: true,
        rules: [],
      },
      {
        name: "timeout",
        type: "input",
        required: true,
        rules: [
          {
            validator: checkTimeout,
          },
        ],
      },
    ],
    HTTP: [
      {
        name: "hostname",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "ip",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "pattern",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "login",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "password",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "addTimestamp",
        required: false,
        type: "checkbox",
        rules: [],
      },
      {
        name: "userAgent",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "timeout",
        type: "input",
        required: true,
        rules: [
          {
            validator: checkTimeout,
          },
        ],
      },
    ],
    JSON: [
      {
        name: "hostname",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "ip",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "pattern",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "expectedValue",
        required: true,
        type: "input",
        rules: [],
      },
      {
        name: "login",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "password",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "userAgent",
        required: false,
        type: "input",
        rules: [],
      },
      {
        name: "timeout",
        type: "input",
        required: true,
        rules: [
          {
            validator: checkTimeout,
          },
        ],
      },
    ],
  };

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      const name = form.getFieldValue("name") as string;

      confirm({
        title: t("supervisions.edit.modals.save.title"),
        content: t("supervisions.edit.modals.save.content", {
          supervision: `${name}`,
        }),
        okText: t("buttons.yes"),
        cancelText: t("buttons.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const verificationPopup = (
    missingNode: boolean,
    missingRecipient: boolean
  ) => {
    Modal.info({
      title: t("supervisions.edit.modals.alert.title"),
      content:
        missingNode && missingRecipient
          ? t("supervisions.edit.modals.alert.content")
          : missingNode
          ? t("supervisions.edit.modals.alert.missingNode")
          : missingRecipient
          ? t("supervisions.edit.modals.alert.missingRecipient")
          : "",
      okText: t("buttons.ok"),
      centered: true,
    });
  };

  useEffect(() => {
    if (supervisionId) {
      void requestGetSupervision(supervisionId);
      void requestGetRegMaintenancesForSupervision({ filter: supervisionId });
      void requestGetTempMaintenancesForSupervision({ filter: supervisionId });
    }
  }, [supervisionId, parentId]);

  useEffect(() => {
    if (parentId) {
      requestGetParentSupervision(parentId).then((response) => {
        if (response.ok && response.data) {
          setParentSupervision(response.data.id);
          form.setFieldsValue({
            parentSupervisionId:
              response.data.name + " (ID : " + response.data.id + ")",
          });
          form.setFieldsValue({
            active: !["DISABLED", "DISABLED_PARENT"].includes(
              response.data.status
            ),
          });
          form.setFieldsValue({ muteAlert: response.data.muteAlert });
          if (response.data.clientId !== null) {
            setClientCanEdit(false);
            form.setFieldsValue({ clientId: response.data.clientId });
            setParentClient(response.data.clientId);
          }
        }
      });
    }
  }, [parentId]);

  useEffect(() => {
    requestGetClients({});
    requestGetRecipients({});
  }, []);

  useEffect(() => {
    return requestGetSupervision.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        setSupervision(result.data);
        setParentSupervision(result.data.parentSupervisionId);
        setAbstract(result.data.details === "{}");
        if (result.data.parentSupervisionId !== null) {
          requestGetParentSupervision(result.data.parentSupervisionId).then(
            (response) => {
              if (response.ok && response.data) {
                form.setFieldsValue({
                  parentSupervisionId:
                    response.data.name + " (ID : " + response.data.id + ")",
                });
                if (response.data.clientId !== null) {
                  setClientCanEdit(false);
                }
              }
            }
          );
        }
        var detailsJson = JSON.parse(result.data.details);
        setType((detailsJson as SupervisionDetails)["type"] ?? "");
        setFields([
          {
            name: ["name"],
            value: result.data.name,
          },
          {
            name: ["parentSupervisionId"],
            value: result.data.parentSupervisionId,
          },
          {
            name: ["clientId"],
            value: result.data.clientId,
          },
          {
            name: ["comments"],
            value: result.data.comments,
          },
          {
            name: ["muteAlert"],
            value: result.data.muteAlert,
          },
          {
            name: ["recipients"],
            value: result.data.recipients,
          },
          {
            name: ["active"],
            value: !["DISABLED", "DISABLED_PARENT"].includes(
              result.data.status
            ),
          },
          {
            name: ["downCyclesBeforeEmail"],
            value: result.data.downCyclesBeforeEmail,
          },
          {
            name: ["supervisionNodesId"],
            value: result.data.nodeSupervisionCurrentStatus,
          },
          {
            name: ["type"],
            value: (detailsJson as SupervisionDetails)["type"] ?? "",
          },
          {
            name: ["hostname"],
            value: (detailsJson as SupervisionDetails)["hostname"] ?? "",
          },
          {
            name: ["timeout"],
            value: (detailsJson as SupervisionDetails)["timeout"] ?? 0,
          },
          {
            name: ["port"],
            value: (detailsJson as SupervisionDetails)["port"] ?? "",
          },
          {
            name: ["ip"],
            value: (detailsJson as SupervisionDetails)["ip"] ?? "",
          },
          {
            name: ["pattern"],
            value: (detailsJson as SupervisionDetails)["pattern"] ?? "",
          },
          {
            name: ["expectedValue"],
            value: (detailsJson as SupervisionDetails)["expectedValue"] ?? "",
          },
          {
            name: ["login"],
            value: (detailsJson as SupervisionDetails)["login"] ?? "",
          },
          {
            name: ["password"],
            value: (detailsJson as SupervisionDetails)["password"] ?? "",
          },
          {
            name: ["addTimestamp"],
            value: (detailsJson as SupervisionDetails)["addTimestamp"] ?? false,
          },
          {
            name: ["userAgent"],
            value: (detailsJson as SupervisionDetails)["userAgent"] ?? "",
          },
        ]);
      } else {
        void message.error(t("supervisions.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestGetClients.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setClients(result.data);
      } else {
        void message.error(t("clients.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestGetRegMaintenancesForSupervision.done.watch(({ result }) => {
      if (result.ok && result.data) {
        form.setFieldsValue({ ["regMaintenances"]: result.data });
      }
    });
  });

  useEffect(() => {
    return requestGetTempMaintenancesForSupervision.done.watch(({ result }) => {
      if (result.ok && result.data) {
        form.setFieldsValue({ ["tempMaintenances"]: result.data });
      }
    });
  });

  useEffect(() => {
    return requestCreateSupervision.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        void message.success(t("supervisions.add.messages.success"));
        history.push("/supervisions");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestTestSupervisionConfiguration.done.watch(({ result }) => {
      if (result.ok && result.data) {
        if (result.data.status === "UP") {
          void message.success(t("supervisions.test.messages.success"));
        } else {
          void message.error(
            t("supervisions.test.messages.error." + result.data.errorCode)
          );
        }
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateSupervision.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        setSupervision(result.data);
        void message.success(t("supervisions.edit.messages.success"));
        history.push("/supervisions");
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateSupervisionStatus.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data && supervision) {
        void message.success(t("editSupervision.status"));
        const newSupervision = {
          ...supervision,
          status: result.data.status,
        };

        setSupervision(newSupervision);
      } else {
        void message.error(result.errorMessage);
      }
    });
  });

  const getDetailsJson = () => {
    if (!abstract) {
      var supervisionDetails: { [key: string]: string } = { type: type };
      detailsColumns[type].forEach((item) => {
        supervisionDetails[item.name] =
          form.getFieldValue(item.name) !== undefined
            ? form.getFieldValue(item.name).toString()
            : item.type === "checkbox"
            ? false
            : "";
      });
      return JSON.stringify(supervisionDetails);
    } else return "{}";
  };

  const verifyRecipientAndNodes = (values: Store) => {
    let missingNode: boolean = false;
    let missingRecipient: boolean = false;
    if (
      values.supervisionNodesId?.map((item: SupervisionNodeCurrentStatus) => {
        return { id: item.supervisionNodeId.id };
      }) === undefined ||
      values.supervisionNodesId?.map((item: SupervisionNodeCurrentStatus) => {
        return { id: item.supervisionNodeId.id };
      }).length === 0
    ) {
      if (!abstract) missingNode = true;
    }
    if (
      values.recipients?.map((item: RecipientItemResponseDto) => {
        return { id: item.id };
      }) === undefined ||
      values.recipients?.map((item: RecipientItemResponseDto) => {
        return { id: item.id };
      }).length === 0
    ) {
      if (!abstract) missingRecipient = true;
    }
    if (missingNode || missingRecipient) {
      verificationPopup(missingNode, missingRecipient);
      return false;
    } else return true;
  };

  const handleSubmit = (values: Store) => {
    if (verifyRecipientAndNodes(values)) {
      void confirmationPopup().then((confirmed: boolean) => {
        if (confirmed) {
          setButtonLoading(true);
          let supervisionToSave: SupervisionSaveRequestDto | null = null;
          if (abstract) {
            supervisionToSave = {
              name: values.name as string,
              parentSupervisionId:
                parentSupervision !== null ? parentSupervision : "",
              comments: values.comments as string,
              details: "{}",
              active:
                values.active === undefined ? true : (values.active as boolean),
              clientId:
                parentClient !== ""
                  ? parentClient
                  : (values.clientId as string),
              muteAlert:
                values.muteAlert === undefined
                  ? false
                  : (values.muteAlert as boolean),
              downCyclesBeforeEmail: null,
              recipients: null,
              supervisionNodes: null,
            };
          } else
            supervisionToSave = {
              name: values.name as string,
              parentSupervisionId:
                parentSupervision !== null ? parentSupervision : "",
              comments: values.comments as string,
              details: getDetailsJson(),
              active:
                values.active === undefined ? true : (values.active as boolean),
              clientId: values.clientId as string,
              muteAlert:
                values.muteAlert === undefined
                  ? false
                  : (values.muteAlert as boolean),
              downCyclesBeforeEmail: values.downCyclesBeforeEmail as number,
              recipients:
                values.recipients?.map((item: RecipientItemResponseDto) => {
                  return { id: item.id };
                }) ?? [],
              supervisionNodes:
                values.supervisionNodesId?.map(
                  (item: SupervisionNodeCurrentStatus) => {
                    return { id: item.supervisionNodeId.id };
                  }
                ) ?? [],
            };
          if (supervisionId) {
            void requestUpdateSupervision({
              id: supervisionId,
              dto: supervisionToSave,
            });
          } else {
            void requestCreateSupervision({
              dto: supervisionToSave,
            });
          }
        }
      });
    }
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    void message.error(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  const testConfiguration = () => {
    requestTestSupervisionConfiguration({ dto: { details: getDetailsJson() } });
  };
  return (
    <>
      {!getResponseReceived && supervisionId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !supervisionId || supervision ? (
        <Form
          {...formItemBlockLayout}
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
            <div className="my-3">
              {editMode && setEditMode ? (
                <>
                  <Popconfirm
                    title={t("forms.actions.modals.cancel.content")}
                    okText={t("buttons.yes")}
                    cancelText={t("buttons.no")}
                    onConfirm={() => {
                      setEditMode(false);
                    }}
                    placement="top"
                    icon={<QuestionCircleOutlined />}
                    className="m-2"
                  >
                    <Button htmlType="reset">
                      <StopOutlined /> {t("buttons.cancel")}
                    </Button>
                  </Popconfirm>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <Button
                      htmlType="reset"
                      className="m-2"
                      onClick={() => history.push(SUPERVISIONS_ROUTE)}
                    >
                      <ArrowLeftOutlined /> {t("buttons.back")}
                    </Button>
                  </div>
                </>
              )}
            </div>
            {supervisionId ? (
              <div className="my-3">
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  <Button
                    className="m-2"
                    onClick={() =>
                      history.push(`/supervisions/${supervisionId}/statistics`)
                    }
                  >
                    <Space>
                      <FontAwesomeIcon icon={faChartBar} />{" "}
                      {t("buttons.statistics")}
                    </Space>
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="my-3">
              {editMode ? (
                <Button
                  className="btn-primary m-2"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  <SaveOutlined /> {t("buttons.save")}
                </Button>
              ) : (
                supervision && (
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    {setEditMode && (
                      <Button
                        htmlType="reset"
                        className="btn-primary m-2"
                        onClick={() => setEditMode(true)}
                      >
                        <EditOutlined /> {t("buttons.edit")}
                      </Button>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <Row>
            <Col xs={24} sm={12}>
              <CardContainer>
                <Row>
                  <Col xs={24}>
                    <InputFormField
                      showLabel
                      module="supervisions.edit"
                      field="name"
                      required={true}
                      emptyErrorMessage={t("forms.errors.mandatory")}
                      readOnly={!editMode}
                      maxLength={FIELD_LENGTH.NAME}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputFormField
                      showLabel
                      module="supervisions.edit"
                      field="comments"
                      emptyErrorMessage={t("forms.errors.mandatory")}
                      readOnly={!editMode}
                      maxLength={FIELD_LENGTH.NAME}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputFormField
                      showLabel
                      module="supervisions.edit"
                      field="parentSupervisionId"
                      emptyErrorMessage={t("forms.errors.mandatory")}
                      readOnly={true}
                      maxLength={FIELD_LENGTH.NAME}
                    />
                  </Col>
                  <Col xs={24}>
                    <Form.Item name={"abstract"}>
                      <CheckboxFormField
                        showLabel
                        module="supervisions.edit"
                        field="abstract"
                        value={true}
                        required={true}
                        readOnly={!editMode}
                        maxLength={FIELD_LENGTH.NAME}
                        onChange={(val) => setAbstract(val)}
                        isChecked={supervision?.details === "{}"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item name={"active"}>
                      <CheckboxFormField
                        showLabel
                        module="supervisions.edit"
                        field="active"
                        value={true}
                        required={true}
                        emptyErrorMessage={t("forms.errors.mandatory")}
                        readOnly={!editMode}
                        maxLength={FIELD_LENGTH.NAME}
                        isChecked={
                          !(
                            supervision?.status === "DISABLED" ||
                            supervision?.status === "PARENT_DISABLED"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item name={"muteAlert"}>
                      <CheckboxFormField
                        showLabel
                        module="supervisions.edit"
                        field="muteAlert"
                        required={true}
                        emptyErrorMessage={t("forms.errors.mandatory")}
                        readOnly={!editMode}
                        maxLength={FIELD_LENGTH.NAME}
                        isChecked={false}
                      />
                    </Form.Item>
                  </Col>
                  {!abstract && (
                    <>
                      <Col xs={24}>
                        <SelectFormField
                          showLabel
                          module="supervisions.edit"
                          field="clientId"
                          required={true}
                          readOnly={!(clientCanEdit && editMode)}
                          options={
                            clients?.map((item) => {
                              return { value: item.id, label: item.name };
                            }) ?? []
                          }
                        />
                      </Col>
                      <Col xs={24}>
                        <InputFormField
                          showLabel
                          module="supervisions.edit"
                          field="downCyclesBeforeEmail"
                          emptyErrorMessage={t("forms.errors.mandatory")}
                          readOnly={!editMode}
                          maxLength={FIELD_LENGTH.NAME}
                          required={true}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </CardContainer>

              {!abstract && (
                <CardContainer
                  title={t("supervisions.edit.form.details.title")}
                >
                  <Row>
                    <Col xs={24}>
                      <SelectFormField
                        showLabel
                        field="type"
                        required={true}
                        onSelect={(value) => setType(value)}
                        module="supervisions.edit"
                        readOnly={!editMode}
                        options={[
                          { value: "PING", label: "PING" },
                          { value: "PORT", label: "PORT" },
                          { value: "HTTP", label: "HTTP" },
                          { value: "JSON", label: "JSON" },
                        ]}
                      />
                    </Col>
                  </Row>
                  {type ? (
                    <>
                      {detailsColumns[type].map((item) => {
                        switch (item.type) {
                          case "input":
                            return (
                              <InputFormField
                                key={item.name}
                                showLabel
                                required={item.required}
                                module="supervisions.edit"
                                field={item.name}
                                emptyErrorMessage={t("forms.errors.mandatory")}
                                readOnly={!editMode}
                                maxLength={FIELD_LENGTH.NAME}
                                rules={item.rules}
                              />
                            );
                          case "checkbox":
                            return (
                              <Form.Item name={item.name}>
                                <CheckboxFormField
                                  showLabel
                                  module="supervisions.edit"
                                  field={item.name}
                                  value={true}
                                  required={item.required}
                                  readOnly={!editMode}
                                  maxLength={FIELD_LENGTH.NAME}
                                  isChecked={
                                    fields.find((value) =>
                                      value.name.find(
                                        (v) => v === "addTimestamp"
                                      )
                                    )?.value as boolean
                                  }
                                />
                              </Form.Item>
                            );
                          default:
                            return <></>;
                        }
                      })}
                      <br />
                      <BasicButton
                        // variant={"clear"}
                        text={t("supervisions.edit.form.details.test")}
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={testConfiguration}
                        iconLeft={false}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </CardContainer>
              )}
            </Col>
            <Col xs={24} sm={12} className={"pl-sm-3"}>
              {!abstract && (
                <CardContainer title={t("supervisionnodes.title")}>
                  <Row>
                    <Col xs={24}>
                      <Form.Item name={"supervisionNodesId"}>
                        <NodesFormField
                          readOnly={!(editMode && hasWritePermission)}
                          module={"supervisions.edit"}
                          field={"supervisionNodesId"}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CardContainer>
              )}
              <CardContainer title={t("regmaintenances.title")}>
                {supervisionId ? (
                  <>
                    <Row>
                      <Col xs={24}>
                        <Form.Item name={"regMaintenances"}>
                          <RegMaintenancesFormField
                            supervisionId={supervisionId}
                            readOnly={!(editMode && hasWritePermission)}
                            module={"supervisions.edit"}
                            field={"regMaintenances"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={24}>
                        {t("regmaintenances.notInCreationMode")}
                      </Col>
                    </Row>
                  </>
                )}
              </CardContainer>
              <CardContainer title={t("tempmaintenances.title")}>
                {supervisionId ? (
                  <>
                    <Row>
                      <Col xs={24}>
                        <Form.Item name={"tempMaintenances"}>
                          <TempMaintenancesFormField
                            supervisionId={supervisionId}
                            readOnly={!(editMode && hasWritePermission)}
                            module={"supervisions.edit"}
                            field={"tempMaintenances"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={24}>
                        {t("tempmaintenances.notInCreationMode")}
                      </Col>
                    </Row>
                  </>
                )}
              </CardContainer>
              {!abstract && (
                <CardContainer title={t("recipients.title")}>
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        name={"recipients"}
                        label={"Liste des destinataires"}
                      >
                        <RecipientsFormField
                          readOnly={!(editMode && hasWritePermission)}
                          module={"supervisions.edit"}
                          field={"recipients"}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CardContainer>
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <div>{t("supervisions.notFound")}</div>
      )}
    </>
  );
};
export default SupervisionForm;
