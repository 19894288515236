import { FunctionComponent, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "@type/form/field.types";
import { ConfigProvider, Form, Modal, Table } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SmileOutlined } from "@ant-design/icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import InputFormField from "@components/inputs/InputFormField";
import { InterruptionHistoryNodeItemResponseDto } from "@state/interruptionHistoryNode/response/interruptionHistoryNode.item.response.dto";
import { InterruptionHistoryNodeUpdateRequestDto } from "@state/interruptionHistoryNode/request/interruptionHistoryNode.update.request.dto";
import { requestUpdateInterruptionHistoriesNode } from "@state/interruptionHistoryNode/InterruptionHistoryNodeEffects";
import { optionsFr, shortFr } from "@utils/date/humanizeDuration";
import { Period } from "@views/statistics/StatisticsScreen";

export interface Props {
  interruptions: InterruptionHistoryNodeItemResponseDto[];
  onChange: any;
  period: Period;
}

export interface DownHistoryElem {
  beginningDate: string;
  endingDate: string;
  duration: string;
  error: string;
  reason: string | ReactNode;

  id: string;
  button: ReactNode;
}

const NodeDownHistory: FunctionComponent<Props> = (props: Props) => {
  const { interruptions, onChange, period } = props;
  const { t } = useTranslation();
  const TABLE_DATE_FORMAT = "YYYY-MM-DD HH:mm";
  const [reasonModalVisible, setReasonModalVisible] = useState<boolean>(false);
  const [reasonFields, setReasonFields] = useState<Field[]>([]);
  const [reasonForm] = Form.useForm();

  const columns = [
    {
      key: "beginningDate",
      dataIndex: "beginningDate",
      title: t("statistics.beginningDate"),
    },
    {
      key: "endingDate",
      dataIndex: "endingDate",
      title: t("statistics.endingDate"),
    },
    { key: "duration", dataIndex: "duration", title: t("statistics.duration") },
    { key: "error", dataIndex: "error", title: t("statistics.error") },
    { key: "reason", dataIndex: "reason", title: t("statistics.reason") },
    { key: "button", dataIndex: "button" },
  ];

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>{t("statistics.empty")}</p>
    </div>
  );

  const generate_data = (
    list: InterruptionHistoryNodeItemResponseDto[] | null
  ) => {
    let data: DownHistoryElem[] = [];
    let i = 0;
    list?.forEach((interruption) => {
      data[i] = {
        beginningDate: moment(interruption.beginningDate).format(
          TABLE_DATE_FORMAT
        ),
        endingDate:
          interruption.endingDate === null
            ? t("statistics.inProgress")
            : moment(interruption.endingDate).format(TABLE_DATE_FORMAT),
        duration:
          interruption.endingDate === null
            ? shortFr(
                moment(period.endingDate).diff(
                  moment(interruption.beginningDate)
                ),
                optionsFr
              )
            : shortFr(
                moment(interruption.endingDate).diff(
                  moment(interruption.beginningDate)
                ),
                optionsFr
              ),
        error:
          interruption.errorCode + " : " + interruption.exceptionStacktrace,
        id: interruption.id,
        reason:
          interruption.comments === "" || interruption.comments === null ? (
            <i>{t("statistics.noReason")}</i>
          ) : (
            interruption.comments
          ),
        button: (
          <FontAwesomeIcon
            icon={faPen}
            className={"action-button"}
            onClick={() => {
              setReasonFields([
                { name: ["comments"], value: interruption.comments },
                { name: ["id"], value: interruption.id },
              ]);
              setReasonModalVisible(true);
            }}
            title={t("statistics.modifyComment")}
          />
        ),
      };
      i++;
    });
    return data;
  };

  let data: DownHistoryElem[] = generate_data(interruptions);

  const update_data = (id: string, reason: string) => {
    let interruption:
      | InterruptionHistoryNodeItemResponseDto
      | undefined = interruptions.find((inter) => inter.id === id);
    if (interruption) {
      let i = interruptions.indexOf(interruption);
      interruption.comments = reason;
      interruptions.splice(i, 1, interruption);
      onChange(interruptions);
      data = generate_data(interruptions);
    }
  };

  const handleUpdateInterruptionReason = () => {
    let id = reasonForm.getFieldValue("id");
    let reason = reasonForm.getFieldValue("comments");
    const interruptionNodeToSave: InterruptionHistoryNodeUpdateRequestDto = {
      comments: reasonForm.getFieldValue("comments"),
    };
    void requestUpdateInterruptionHistoriesNode({
      id: id,
      dto: interruptionNodeToSave,
    });
    update_data(id, reason);
    setReasonModalVisible(false);
  };

  const handleCancelUpdateInterruptionReason = () => {
    setReasonModalVisible(false);
  };

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={data}
      />
      <Form
        form={reasonForm}
        onFinish={handleUpdateInterruptionReason}
        fields={reasonFields}
      >
        <Modal
          title={t("statistics.modifyComment")}
          visible={reasonModalVisible}
          onOk={handleUpdateInterruptionReason}
          onCancel={handleCancelUpdateInterruptionReason}
          cancelText={"Annuler"}
        >
          <InputFormField
            module="statistics.interruptionHistoryGlobal.update"
            field="comments"
            emptyErrorMessage={t("forms.errors.mandatory")}
          />
        </Modal>
      </Form>
    </ConfigProvider>
  );
};

export default NodeDownHistory;
