import login from "./login.json";
import dashboard from "./dashboard.json";
import menu from "./menu.json";
import header from "./header.json";
import users from "./users.json";
import clients from "./clients.json";
import supervisions from "./supervisions.json";
import supervisionnodes from "./supervisionnodes.json";
import buttons from "./buttons.json";
import forms from "./forms.json";
import tasks from "./tasks.json";
import recipients from "./recipients.json";
import regmaintenances from "./regmaintenances.json";
import tempmaintenances from "./tempmaintenances.json";
import statistics from "./statistics.json";
import error from "./error.json";

const translationFR = {
  ...login,
  ...dashboard,
  ...header,
  ...menu,
  ...users,
  ...clients,
  ...supervisions,
  ...supervisionnodes,
  ...buttons,
  ...forms,
  ...tasks,
  ...recipients,
  ...regmaintenances,
  ...tempmaintenances,
  ...statistics,
  ...error,
};

export default translationFR;
