import { restPostHandlerWithSuffix } from "@utils/rest/RestClient";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { StatisticsItemResponseDto } from "@state/statistics/dto/response/statistics.item.response.dto";
import { StatisticsGetDto } from "@state/statistics/dto/request/statistics.get.dto";

const baseUrl = configuration.backendBaseUrl;
const statisticsUrl = baseUrl + "/statistics/supervision/";

export const requestPostStatistics = createEffect({
  handler: restPostHandlerWithSuffix<
    StatisticsGetDto,
    StatisticsItemResponseDto
  >(statisticsUrl),
});
