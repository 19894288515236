import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { ClientItemResponseDto } from "@state/clients/dto/response/client.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { ClientDetailsResponseDto } from "@state/clients/dto/response/client.details.response.dto";
import { ClientSaveRequestDto } from "@state/clients/dto/request/client.save.request.dto";

const baseUrl = configuration.backendBaseUrl;
const clientsUrl = baseUrl + "/clients/";

export const requestGetClientsPage = createEffect({
  handler: restListHandlerPagination<Pageable<ClientItemResponseDto>>(
    clientsUrl
  ),
});

export const requestGetClients = createEffect({
  handler: restListHandler<ClientItemResponseDto>(clientsUrl),
});

export const requestGetClient = createEffect({
  handler: restDetailsHandler<ClientDetailsResponseDto>(clientsUrl),
});

export const requestCreateClient = createEffect({
  handler: restCreationHandler<ClientSaveRequestDto, ClientDetailsResponseDto>(
    clientsUrl
  ),
});

export const requestUpdateClient = createEffect({
  handler: restUpdateHandler<ClientSaveRequestDto, ClientDetailsResponseDto>(
    clientsUrl
  ),
});

export const requestDeleteClient = createEffect({
  handler: restDeletionHandler(clientsUrl),
});
