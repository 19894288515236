import { AdalConfig, AuthenticationContext } from "react-adal";

export const appId = "eeefaad4-3259-4d63-a5af-c9e0bca98bbd";
export const adalConfig: AdalConfig = {
  cacheLocation: "localStorage",
  clientId: appId,
  postLogoutRedirectUri: window.location.origin,
  tenant: "17d3781f-d627-46ef-9678-00af052aca06",
  endpoints: {
    api: appId,
  },
};
export const authContext = new AuthenticationContext(adalConfig);
