import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";

export interface SelectFieldProps {
  module: string;
  field: string;
  options: {
    value: string;
    label: string;
  }[];
  showLabel?: boolean;
  required?: boolean;
  readOnly?: boolean;
  mode?: "multiple";
  onSelect?: (value: string) => void;
  onDeselect?: (value: string) => void;
  tagRender?: (
    props: any
  ) => ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const SelectFormField = ({
  module,
  field,
  options,
  showLabel,
  required,
  readOnly,
  mode,
  onSelect,
  onDeselect,
  tagRender,
}: SelectFieldProps): ReactElement => {
  const { t } = useTranslation();

  const rules =
    !readOnly && required
      ? [
          {
            required: true,
            message: t("forms.errors.mandatory"),
          },
        ]
      : [];

  return (
    <Form.Item
      name={field}
      key={field}
      label={
        readOnly || showLabel ? (
          <span>{t(`${module}.form.fields.${field}.label`)}&nbsp;</span>
        ) : (
          ""
        )
      }
      rules={rules}
    >
      <Select
        mode={mode}
        disabled={readOnly}
        id={`form-${field}`}
        placeholder={t(`${module}.form.fields.${field}.placeholder`)}
        onSelect={onSelect}
        onDeselect={onDeselect}
        tagRender={tagRender}
      >
        {options.map((option) => (
          <Select.Option key={`value_${option.value}`} value={option.value}>
            {t(option.label)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export { SelectFormField };
