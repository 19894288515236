import { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import UserForm from "@views/users/details/UserForm";

const UserAddScreen: FunctionComponent<RouteComponentProps> = () => {
  return (
    <PageLayout>
      <>
        <PageBody>
          <UserForm editMode />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default UserAddScreen;
