import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { SupervisionNodeItemResponseDto } from "@state/supervisionnodes/dto/response/supervisionnode.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { SupervisionNodeSaveRequestDto } from "@state/supervisionnodes/dto/request/supervisionnode.save.request.dto";
import { SupervisionNodeDetailsResponseDto } from "@state/supervisionnodes/dto/response/supervisionnode.details.response.dto";

const baseUrl = configuration.backendBaseUrl;
const supervisionNodesUrl = baseUrl + "/supervisionnodes/";

export const requestGetSupervisionNodesPage = createEffect({
  handler: restListHandlerPagination<Pageable<SupervisionNodeItemResponseDto>>(
    supervisionNodesUrl
  ),
});

export const requestGetSupervisionNodes = createEffect({
  handler: restListHandler<SupervisionNodeItemResponseDto>(supervisionNodesUrl),
});

export const requestGetSupervisionNode = createEffect({
  handler: restDetailsHandler<SupervisionNodeDetailsResponseDto>(
    supervisionNodesUrl
  ),
});

export const requestCreateSupervisionNode = createEffect({
  handler: restCreationHandler<
    SupervisionNodeSaveRequestDto,
    SupervisionNodeDetailsResponseDto
  >(supervisionNodesUrl),
});

export const requestUpdateSupervisionNode = createEffect({
  handler: restUpdateHandler<
    SupervisionNodeSaveRequestDto,
    SupervisionNodeDetailsResponseDto
  >(supervisionNodesUrl),
});

export const requestDeleteSupervisionNode = createEffect({
  handler: restDeletionHandler(supervisionNodesUrl),
});
