import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Divider, message, Table } from "antd";
import { message, Popover, Select, Space, Spin, Tree } from "antd";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import { RouteComponentProps, useHistory } from "react-router-dom";
import BasicButton from "@components/buttons/BasicButton";
import { SupervisionItemResponseDto } from "@state/supervisions/dto/response/supervision.item.response.dto";
import {
  requestDeleteSupervision,
  requestDuplicateSupervision,
  requestGetSupervisions,
  requestUpdateSupervisionField,
} from "@state/supervisions/SupervisionEffects";
import { getSupervisionColor } from "@utils/enums/status.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAltV,
  faCopy,
  faEllipsisV,
  faEye,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faSignal,
  faTrashAlt,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { hasPermission } from "@utils/permission-helper";
import moment from "moment";
import "moment/locale/fr";
import { showConfirm } from "@components/modals/ConfirmModal";
import { requestGetClients } from "@state/clients/ClientEffects";
import { ClientItemResponseDto } from "@state/clients/dto/response/client.item.response.dto";

const TreeNode = Tree.TreeNode;

const hasReadPermission = hasPermission("supervisions");

const SupervisionsScreen: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [clients, setClients] = useState<ClientItemResponseDto[]>([]);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(false);

  const [supervisions, setSupervisions] = useState<
    SupervisionItemResponseDto[]
  >([]);
  const [supervisionsFiltered, setSupervisionsFiltered] = useState<
    SupervisionItemResponseDto[]
  >([]);

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [moveMode, setMoveMode] = useState<boolean>(false);

  const fetchSupervisions = useCallback(() => {
    if (hasReadPermission) {
      requestGetSupervisions({})
        .catch(() => {
          void message.error(t("supervisions.list.messages.loading-error"));
        })
        .finally(() => {
          setDataHasLoaded(true);
        });
    }
  }, [t]);

  useEffect(() => {
    fetchSupervisions();
    //Update de la liste toutes les 3 mins (180s)
    const interval = setInterval(() => {
      fetchSupervisions();
    }, 180000);

    return () => clearInterval(interval);
  }, [fetchSupervisions]);

  useEffect(() => {
    return requestGetSupervisions.done.watch(({ result }) => {
      if (result.ok && result.data) {
        var tree = list_to_tree(result.data);
        setSupervisions(tree);
        setSupervisionsFiltered(tree);
      }
    });
  });

  useEffect(() => {
    requestGetClients({});
  }, []);
  useEffect(() => {
    return requestGetClients.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setClients(result.data);
      } else {
        void message.error(t("clients.notFound"));
      }
    });
  });

  const list_to_tree = (list: SupervisionItemResponseDto[]) => {
    var map = {},
      node,
      roots: SupervisionItemResponseDto[] = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      // @ts-ignore
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentSupervisionId) {
        // @ts-ignore
        list[map[node.parentSupervisionId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      } else return 0;
    });
    // return roots
  };

  const renderStatusBar = (lastStates: string[]) => {
    return (
      <div className={"status-bar-container ml-5"}>
        {lastStates.map((status, i) => (
          <div
            key={i}
            className={`status-bar status-bar-${getSupervisionColor(status)}`}
          />
        ))}
      </div>
    );
  };

  const renderActionButtons = (supervision: SupervisionItemResponseDto) => {
    return (
      <div
        className={"action-button-container"}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon
          icon={faEye}
          className={"action-button"}
          onClick={() => history.push(`/supervisions/${supervision.id}`)}
          title={t("supervisions.list.actions.visualise")}
        />
        <FontAwesomeIcon
          icon={faPlusCircle}
          className={"action-button"}
          onClick={() =>
            history.push(`/supervisions/add`, { parentId: supervision.id })
          }
          title={t("supervisions.list.actions.addChild")}
        />
        <FontAwesomeIcon
          icon={faCopy}
          className={"action-button"}
          title={t("supervisions.list.actions.duplicate")}
          onClick={() => {
            copySupervision(supervision.id, "Copie de " + supervision.name);
          }}
        />
        <FontAwesomeIcon
          icon={faArrowsAltV}
          className={"action-button"}
          title={t("supervisions.list.actions.changeParent")}
          onClick={() => {
            showConfirm(
              t("supervisions.edit.modals.enable.move"),
              () => {
                setMoveMode(true);
              },
              () => {
                setMoveMode(false);
              }
            );
          }}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className={"action-button"}
          title={t("supervisions.list.actions.delete")}
          onClick={() => {
            deleteSupervision(supervision.id);
          }}
        />
        <FontAwesomeIcon
          icon={faSignal}
          className={"action-button"}
          title={t("supervisions.list.actions.stats")}
          onClick={() =>
            history.push(`/supervisions/${supervision.id}/statistics`)
          }
        />
        {supervision.muteAlert ? (
          <FontAwesomeIcon
            icon={faVolumeUp}
            className={"action-button"}
            title={t("supervisions.list.actions.unmute")}
            onClick={() => {
              updateSupervisionField(supervision.id, "muteAlert", "false");
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faVolumeMute}
            className={"action-button"}
            title={t("supervisions.list.actions.mute")}
            onClick={() => {
              updateSupervisionField(supervision.id, "muteAlert", "true");
            }}
          />
        )}
        {supervision.status === "DISABLED" ||
        supervision.status === "DISABLED_PARENT" ||
        supervision.active! ? (
          <FontAwesomeIcon
            icon={faPowerOff}
            className={"action-button"}
            title={t("supervisions.list.actions.resume")}
            onClick={() => {
              updateSupervisionField(supervision.id, "active", "true");
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPowerOff}
            className={"action-button"}
            title={t("supervisions.list.actions.pause")}
            onClick={() => {
              updateSupervisionField(supervision.id, "active", "false");
            }}
          />
        )}
      </div>
    );
  };

  const getDurationFromNow = (datetime: string) => {
    moment.locale("fr");
    let dateTime = moment(datetime, "YYYY-MM-DD HH:mm:ss");
    return dateTime.isValid()
      ? "Dernier test " + moment(datetime, "YYYY-MM-DD HH:mm:ss").fromNow()
      : "Aucun test effectué";
  };

  const updateSupervisionField = (id: string, field: string, value: string) => {
    requestUpdateSupervisionField(id, field, value)
      .then(() => {
        // Refresh supervisions list
        requestGetSupervisions({});
        if (moveMode) setMoveMode(false);
      })
      .catch(() => {
        void message.error(t("supervisions.edit.messages.error"));
      });
  };

  const copySupervision = (id: string, newName: string) => {
    requestDuplicateSupervision(id, newName)
      .then((result) => {
        history.push(`/supervisions/${result.id}/edit`, { toEdit: true });
      })
      .catch(() => {
        void message.error(t("supervisions.add.messages.error"));
      });
  };

  const deleteSupervision = (id: string) => {
    requestDeleteSupervision({ id: id }).then((result) => {
      if (result.ok) {
        // Refresh supervisions list
        requestGetSupervisions({});
      } else {
        void message.error(t("supervisions.add.messages.error"));
      }
    });
  };

  const renderSupervision = (item: SupervisionItemResponseDto) => {
    return (
      <TreeNode
        key={item.id}
        title={
          <div
            className={`tree-node status-${getSupervisionColor(item.status)}`}
            onClick={() => {
              setSelectedId(selectedId === item.id ? null : item.id);
            }}
          >
            <div
              className={
                "text-truncate font-weight-bold text-lg-left flex-grow-1"
              }
            >
              {item.name}
            </div>
            <Space />
            {item.details === "{}" ? (
              <></>
            ) : (
              <div className={"text-truncate d-none d-sm-block"}>
                {getDurationFromNow(item.checkedAt)}
              </div>
            )}
            {renderStatusBar(item.lastTenStatus)}
            <div className={"status-indicator mx-1"}>
              {item.muteAlert ? (
                <FontAwesomeIcon
                  icon={faVolumeMute}
                  className={"action-button"}
                  title={t("supervisions.actions.resume")}
                  onClick={() => {
                    updateSupervisionField(item.id, "muteAlert", "false");
                  }}
                />
              ) : (
                item.status === "DISABLED" && (
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className={"action-button"}
                    title={t("supervisions.actions.resume")}
                    onClick={() => {
                      updateSupervisionField(item.id, "active", "true");
                    }}
                  />
                )
              )}
            </div>
            {hasPermission("supervisions", true) && (
              <Popover
                visible={selectedId === item.id}
                content={renderActionButtons(item)}
                placement="bottomRight"
              >
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className={"action-ellipsis"}
                />
              </Popover>
            )}
          </div>
        }
      >
        {item.children &&
          item.children.map((child) => renderSupervision(child))}
      </TreeNode>
    );
  };

  return (
    <PageLayout>
      <>
        <PageBody>
          <div className="d-flex flex-column align-items-stretch flex-sm-row">
            <div className="w-100 d-flex justify-content-between mb-3 flex-column flex-sm-row">
              <h4 className="text-primary">{t("supervisions.title")}</h4>
              <div>
                <Space>
                  <Select
                    defaultValue={"*"}
                    options={[{ value: "*", label: "Tous les clients" }].concat(
                      clients?.map((item) => {
                        return { value: item.id, label: item.name };
                      })
                    )}
                    onSelect={(value) => {
                      value === "*"
                        ? setSupervisionsFiltered(supervisions)
                        : setSupervisionsFiltered(
                            supervisions.filter(
                              (supervision) => supervision.clientId === value
                            )
                          );
                    }}
                  />
                  {hasPermission("supervisions", true) && (
                    <BasicButton
                      variant={"default"}
                      text={t("supervisions.actions.add")}
                      onClick={() => history.push("/supervisions/add")}
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      iconLeft={false}
                    />
                  )}
                </Space>
              </div>
            </div>
          </div>
          {hasReadPermission ? (
            dataHasLoaded ? (
              supervisionsFiltered.length > 0 ? (
                <Tree
                  style={{ whiteSpace: "break-spaces" }}
                  draggable={moveMode}
                  onDrop={(info) => {
                    updateSupervisionField(
                      info.dragNode.key.toString(),
                      "parentSupervisionId",
                      info.dropPosition === -1
                        ? "null"
                        : info.node.key.toString()
                    );
                  }}
                  defaultExpandAll={true}
                  onSelect={(record) => {
                    return {
                      onClick: () => history.push(`/supervisions/${record}`),
                    };
                  }}
                >
                  {supervisionsFiltered.map((item) => renderSupervision(item))}
                </Tree>
              ) : (
                t("supervisions.list.empty")
              )
            ) : (
              <Spin />
            )
          ) : (
            t("supervisions.unauthorized")
          )}
        </PageBody>
      </>
    </PageLayout>
  );
};

export default SupervisionsScreen;
