import { FC, ComponentType } from "react";
import { /*Redirect,*/ Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
// import { LOGIN_ROUTE } from "@utils/Routes";
// import { useStore } from "effector-react";
// import { accessTokenStore } from "@state/auth/AuthStore";

const ProtectedRoute: FC<{
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  path: string;
  profile?: string;
  exact?: boolean;
}> = (props) => {
  // const isAuthentified = useStore(accessTokenStore);

  // if (!isAuthentified) {
  //   return <Redirect to={LOGIN_ROUTE} />;
  // }

  return (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );
};

export default ProtectedRoute;
