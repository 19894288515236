import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { InterruptionHistoryGlobalItemResponseDto } from "@state/interruptionHistoryGlobal/dto/response/interruptionHistoryGlobal.item.response.dto";
import moment from "moment";
import { Select, Table } from "antd";

import { Option } from "antd/es/mentions";
import { Column } from "@ant-design/charts";
import { Period } from "@views/statistics/StatisticsScreen";
import { InterruptionHistoryNodeItemResponseDto } from "@state/interruptionHistoryNode/response/interruptionHistoryNode.item.response.dto";

export interface Props {
  interruptions:
    | InterruptionHistoryGlobalItemResponseDto[]
    | InterruptionHistoryNodeItemResponseDto[]
    | null;
  period: Period;
}
const DownGraph: FunctionComponent<Props> = (props: Props) => {
  const { interruptions, period } = props;
  const { t } = useTranslation();
  const [graphUnit, setGraphUnit] = useState<string>("hour");

  const compute_values = (
    list:
      | (
          | InterruptionHistoryGlobalItemResponseDto
          | InterruptionHistoryNodeItemResponseDto
        )[]
      | null
  ) => {
    moment.locale("fr");
    let data = [];
    let index = 0;
    switch (graphUnit) {
      case "hour":
      default:
        for (let i = 0; i < 24; i++) {
          const compt =
            list?.filter(
              (interruption: { beginningDate: moment.MomentInput }) =>
                moment(interruption.beginningDate).hour() === i
            )?.length || 0;
          data[i] = { type: i + ":00", value: compt };
        }
        return data;
      case "day":
        index = 0;
        for (let i = 1; i < 8; i++) {
          const compt =
            list?.filter(
              (interruption: { beginningDate: moment.MomentInput }) =>
                moment(interruption.beginningDate).weekday() === i
            )?.length || 0;
          data[index] = { type: moment().day(i).format("dddd"), value: compt };
          index++;
        }
        return data;
      case "week":
        index = 0;
        for (
          let i = moment(period.beginningDate).isoWeek();
          i < moment(period.endingDate).isoWeek() + 1;
          i++
        ) {
          const compt =
            list?.filter(
              (interruption: { beginningDate: moment.MomentInput }) =>
                moment(interruption.beginningDate).isoWeek() === i
            )?.length || 0;
          data[index] = { type: i, value: compt };
          index++;
        }
        return data;
      case "month":
        index = 0;
        for (
          let i = moment(period.beginningDate).month();
          i < moment(period.endingDate).month() + 1;
          i++
        ) {
          const compt =
            list?.filter(
              (interruption: { beginningDate: moment.MomentInput }) =>
                moment(interruption.beginningDate).month() === i
            )?.length || 0;
          data[index] = {
            type: moment(period.beginningDate)
              .add(index, "month")
              .format("MMM"),
            value: compt,
          };
          index++;
        }
        return data;
    }
  };

  const data = compute_values(interruptions);

  var config = {
    data: data,
    xField: "type",
    yField: "value",
    legend: true,
  };

  function handleChange(value: string) {
    setGraphUnit(value);
  }

  const select = (
    <Select defaultValue={"hour"} onChange={handleChange}>
      <Option value="hour">{t("statistics.hour")}</Option>
      <Option value="day">{t("statistics.day")}</Option>
      <Option value="week">{t("statistics.week")}</Option>
      <Option value="month">{t("statistics.month")}</Option>
    </Select>
  );

  return (
    <>
      <Table
        showHeader={false}
        pagination={false}
        columns={[
          { key: "title", dataIndex: "title" },
          { key: "showBy", dataIndex: "showBy", align: "right" as "right" },
        ]}
        dataSource={[
          {
            title: <b>{t("statistics.downVisualisation")}</b>,
            showBy: (
              <b>
                {t("statistics.showBy")} {select}
              </b>
            ),
          },
        ]}
      />
      <br />
      <Column {...config} />
    </>
  );
};
export default DownGraph;
