export enum PROFILES {
  "SUPER_ADMIN" = "SUPER_ADMIN",
  "GUEST" = "GUEST",
  "USERS_READER" = "USERS_READER",
  "USERS_MANAGER" = "USERS_MANAGER",
  "CLIENTS_READER" = "CLIENTS_READER",
  "CLIENTS_MANAGER" = "CLIENTS_MANAGER",
  "SUPERVISIONS_READER" = "SUPERVISIONS_READER",
  "SUPERVISIONS_MANAGER" = "SUPERVISIONS_MANAGER",
  "MAINTENANCES_READER" = "MAINTENANCES_READER",
  "MAINTENANCES_MANAGER" = "MAINTENANCES_MANAGER",
  "INSTANCES_READER" = "INSTANCES_READER",
  "INSTANCES_MANAGER" = "INSTANCES_MANAGER",
  "RECIPIENTS_READER" = "RECIPIENTS_READER",
  "RECIPIENTS_MANAGER" = "RECIPIENTS_MANAGER",
  "INTERRUPTION_HISTORIES_READER" = "INTERRUPTION_HISTORIES_READER",
  "INTERRUPTION_HISTORIES_MANAGER" = "INTERRUPTION_HISTORIES_MANAGER",
}

export const PROFILES_OPTIONS = [
  {
    value: "SUPER_ADMIN",
    label: "users.labels.profiles.SUPER_ADMIN",
  },
  {
    value: "GUEST",
    label: "users.labels.profiles.GUEST",
  },
  {
    value: "USERS_MANAGER",
    label: "users.labels.profiles.USERS_MANAGER",
  },
  {
    value: "USERS_READER",
    label: "users.labels.profiles.USERS_READER",
  },
  {
    value: "CLIENTS_MANAGER",
    label: "users.labels.profiles.CLIENTS_MANAGER",
  },
  {
    value: "CLIENTS_READER",
    label: "users.labels.profiles.CLIENTS_READER",
  },
  {
    value: "SUPERVISIONS_MANAGER",
    label: "users.labels.profiles.SUPERVISIONS_MANAGER",
  },
  {
    value: "SUPERVISIONS_READER",
    label: "users.labels.profiles.SUPERVISIONS_READER",
  },
  {
    value: "MAINTENANCES_MANAGER",
    label: "users.labels.profiles.MAINTENANCES_MANAGER",
  },
  {
    value: "MAINTENANCES_READER",
    label: "users.labels.profiles.MAINTENANCES_READER",
  },
  {
    value: "INSTANCES_MANAGER",
    label: "users.labels.profiles.INSTANCES_MANAGER",
  },
  {
    value: "INSTANCES_READER",
    label: "users.labels.profiles.INSTANCES_READER",
  },
  {
    value: "RECIPIENTS_MANAGER",
    label: "users.labels.profiles.RECIPIENTS_MANAGER",
  },
  {
    value: "RECIPIENTS_READER",
    label: "users.labels.profiles.RECIPIENTS_READER",
  },
  {
    value: "INTERRUPTION_HISTORIES_MANAGER",
    label: "users.labels.profiles.INTERRUPTION_HISTORIES_MANAGER",
  },
  {
    value: "INTERRUPTION_HISTORIES_READER",
    label: "users.labels.profiles.INTERRUPTION_HISTORIES_READER",
  },
];

export const getProfileLabel = (statusCode: string) => {
  return (
    PROFILES_OPTIONS.find((option) => option.value === statusCode)?.label ?? ""
  );
};
