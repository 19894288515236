import { FC, useEffect } from "react";
import { Switch } from "react-router-dom";
import { Layout } from "antd";
import ProtectedRoute from "@components/routes/ProtectedRoute";
import "./styles/styles.scss";
import "./utils/i18n/i18n";
import UsersScreen from "@views/users/list/UsersScreen";
import UserAddScreen from "@views/users/add/UserAddScreen";
import UserScreen from "@views/users/details/UserScreen";
import { authentifiedStore } from "./state/auth/AuthStore";
import { login } from "@state/auth/AuthEvents";
import SupervisionsScreen from "@views/supervisions/list/SupervisionsScreen";
import SupervisionAddScreen from "@views/supervisions/add/SupervisionAddScreen";
import SupervisionScreen from "@views/supervisions/details/SupervisionScreen";
import ClientsScreen from "@views/clients/list/ClientsScreen";
import ClientAddScreen from "@views/clients/add/ClientAddScreen";
import ClientScreen from "@views/clients/details/ClientScreen";
import SupervisionNodeAddScreen from "@views/supervisionnodes/add/SupervisionNodesAddScreen";
import SupervisionNodesScreen from "@views/supervisionnodes/list/SupervisionNodesScreen";
import SupervisionNodeScreen from "@views/supervisionnodes/details/SupervisionNodeScreen";
import StatisticsScreen from "@views/statistics/StatisticsScreen";
import { config } from "@fortawesome/fontawesome-svg-core";
import { adalGetToken } from "react-adal";
import { appId, authContext } from "./adalConfig";

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false;

export const App: FC = () => {
  useEffect(() => {
    if (!authentifiedStore.getState()?.user) {
      login();
      // requestUpdateAuthentifiedContext().then((response) => {
      //     if (response.ok && response.data) {
      //         setUser(response.data);
      //     }
      // })
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adalKeepAlive = () => {
    adalGetToken(authContext, appId)
      .then((token) => {
        if (token != null) {
          console.log(token);
          localStorage.setItem("adal.idtoken", token);
        }
      })
      .catch((reason) => console.log(reason));
  };

  useEffect(() => {
    //Update du token toutes les 10 mins (600000ms)
    const interval = setInterval(() => {
      adalKeepAlive();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      {/*<AppInfosRibbon />*/}

      <Switch>
        <ProtectedRoute exact path="/" component={SupervisionsScreen} />
        <ProtectedRoute exact path="/users" component={UsersScreen} />
        <ProtectedRoute exact path="/users/add" component={UserAddScreen} />
        <ProtectedRoute exact path="/users/:userId" component={UserScreen} />
        <ProtectedRoute exact path="/clients" component={ClientsScreen} />
        <ProtectedRoute exact path="/clients/add" component={ClientAddScreen} />
        <ProtectedRoute
          exact
          path="/clients/:clientId"
          component={ClientScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisions"
          component={SupervisionsScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisions/:supervisionId/statistics"
          component={StatisticsScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisions/add"
          component={SupervisionAddScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisions/:supervisionId/:edit?"
          component={SupervisionScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisionnodes"
          component={SupervisionNodesScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisionnodes/add"
          component={SupervisionNodeAddScreen}
        />
        <ProtectedRoute
          exact
          path="/supervisionnodes/:supervisionNodeId"
          component={SupervisionNodeScreen}
        />
      </Switch>
    </Layout>
  );
};

export default App;
