import { FunctionComponent, FormEvent, useState, useEffect } from "react";
import { Col, Form, message, Modal, Row, Table, TableColumnType } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { FIELD_LENGTH, formItemBlockLayout } from "@utils/Constant";
import { Field } from "@type/form/field.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import BasicButton from "@components/buttons/BasicButton";
import { TempMaintenancesItemResponseDto } from "@state/tempmaintenances/dto/response/tempmaintenances.item.response.dto";
import { TempMaintenancesSaveRequestDto } from "@state/tempmaintenances/dto/request/tempmaintenances.save.request.dto";
import {
  requestCreateTempMaintenance,
  requestDeleteTempMaintenance,
  requestUpdateTempMaintenance,
} from "@state/tempmaintenances/TempMaintenancesEffects";
import DatePickerFormField from "@components/inputs/DatePickerFormField";
import InputFormField from "@components/inputs/InputFormField";
import moment from "moment";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onChange?: (value: TempMaintenancesItemResponseDto[]) => void;
  value?: TempMaintenancesItemResponseDto[];
  supervisionId?: string;
}

const TempMaintenancesFormField: FunctionComponent<PropsType> = (
  props: PropsType
) => {
  const { readOnly, value, onChange, supervisionId } = props;
  const { t } = useTranslation();

  const DATE_FORMAT = "YYYY-MM-DD HH:mm";
  const DISPLAY_DATE_FORMAT = "DD/MM/YYYY à HH[h]mm";
  const FORM_DATE_FORMAT = "YYYY-MM-DDTHH:mm";

  const [tempMaintenances, setTempMaintenances] = useState<
    TempMaintenancesItemResponseDto[] | undefined
  >(undefined);

  const [
    maintenanceModalVisible,
    setMaintenanceModalVisible,
  ] = useState<boolean>(false);

  const [tempMaintenancesForm] = Form.useForm();

  const [tempMaintenancesFields, setTempMaintenancesFields] = useState<Field[]>(
    []
  );

  useEffect(() => {
    if (tempMaintenances === undefined) setTempMaintenances(value!);
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(tempMaintenances!);
  }, [tempMaintenances]);

  useEffect(() => {
    return requestCreateTempMaintenance.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMaintenanceModalVisible(false);
        setTempMaintenances((tempMaintenances) =>
          tempMaintenances
            ? [...tempMaintenances, result.data!]
            : [result.data!]
        );
        clearMaintenanceForm();
        void message.success(t("tempmaintenances.add.messages.success"));
      } else {
        void message.error(t("tempmaintenances.add.messages.error"));
      }
    });
  });

  useEffect(() => {
    return requestUpdateTempMaintenance.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMaintenanceModalVisible(false);
        let index = tempMaintenances?.findIndex(
          (item) => item.id === result.data?.id
        );
        if (index != undefined && index > -1) {
          var newMaintenances = tempMaintenances?.slice();
          newMaintenances![index] = result.data;
          setTempMaintenances(newMaintenances);
        }
        clearMaintenanceForm();
        void message.success(t("tempmaintenances.edit.messages.success"));
      } else {
        void message.error(t("tempmaintenances.edit.messages.error"));
      }
    });
  });

  const handleCreateRegMaintenance = () => {
    let id = tempMaintenancesForm.getFieldValue("id");
    const maintenanceToSave: TempMaintenancesSaveRequestDto = {
      supervisionId: supervisionId!,
      beginningDate: moment(
        tempMaintenancesForm.getFieldValue("beginningDate"),
        DATE_FORMAT
      ).format(FORM_DATE_FORMAT),
      endingDate: moment(
        tempMaintenancesForm.getFieldValue("endingDate"),
        DATE_FORMAT
      ).format(FORM_DATE_FORMAT),
      reason: tempMaintenancesForm.getFieldValue("reason"),
    };
    if (id) {
      void requestUpdateTempMaintenance({
        id: id,
        dto: maintenanceToSave,
      });
    } else {
      void requestCreateTempMaintenance({
        dto: maintenanceToSave,
      });
    }
  };

  const clearMaintenanceForm = () => {
    setTempMaintenancesFields([
      { name: ["beginningDate"], value: undefined },
      { name: ["endingDate"], value: undefined },
      { name: ["supervisionId"], value: undefined },
      { name: ["reason"], value: "" },
    ]);
  };

  const showMaintenanceModal = () => {
    setMaintenanceModalVisible(true);
  };

  const handleCancelCreation = () => {
    setMaintenanceModalVisible(false);
  };

  const handleDeleteMaintenance = (id: string) => {
    requestDeleteTempMaintenance({ id: id }).then((result) => {
      if (result.ok) {
        let index = tempMaintenances?.findIndex((item) => item.id === id);
        if (index && index !== -1) {
          var newMaintenances = tempMaintenances?.slice();
          newMaintenances!.splice(index, 1);
          setTempMaintenances(newMaintenances);
        }
        void message.success(t("tempmaintenances.delete.messages.success"));
      } else {
        void message.error(t("tempmaintenances.delete.messages.error"));
      }
    });
  };

  const columns: TableColumnType<TempMaintenancesItemResponseDto>[] = [
    {
      title: "",
      dataIndex: ["beginningDate", "endingDate"],
      render: (text: string, record: TempMaintenancesItemResponseDto) => {
        return (
          <span>
            {moment(record.beginningDate, DATE_FORMAT).format(
              DISPLAY_DATE_FORMAT
            )}{" "}
            -{" "}
            {moment(record.endingDate, DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}
          </span>
        );
      },
    },
    {
      title: "Raison",
      dataIndex: "reason",
    },
  ];

  const actionColumn: TableColumnType<TempMaintenancesItemResponseDto> = {
    title: "Actions",
    key: "actions",
    render: (text: string, record: TempMaintenancesItemResponseDto) => (
      <div>
        <FontAwesomeIcon
          icon={faEdit}
          className={"action-button"}
          onClick={() => {
            tempMaintenancesForm.setFields([
              { name: ["id"], value: record.id },
              { name: ["beginningDate"], value: record.beginningDate },
              { name: ["endingDate"], value: record.endingDate },
              { name: ["reason"], value: record.reason },
            ]);
            setMaintenanceModalVisible(true);
          }}
        />
        <FontAwesomeIcon
          icon={faTimes}
          className={"action-button"}
          onClick={() => {
            handleDeleteMaintenance(record.id);
          }}
        />
      </div>
    ),
  };

  return (
    <>
      {tempMaintenances && tempMaintenances.length ? (
        <Table
          pagination={false}
          columns={readOnly ? columns : [...columns, actionColumn]}
          dataSource={tempMaintenances}
        ></Table>
      ) : (
        t("tempmaintenances.list.empty")
      )}
      {!readOnly && supervisionId && (
        <>
          <div className={"d-flex w-100 align-content-end flex-column my-2"}>
            <BasicButton
              variant={"clear"}
              text={t("tempmaintenances.add.title")}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={showMaintenanceModal}
              iconLeft={false}
            />
          </div>
        </>
      )}
      <Form
        {...formItemBlockLayout}
        form={tempMaintenancesForm}
        onFinish={handleCreateRegMaintenance}
        onFinishFailed={() => alert("something's wrong")} // TODO: do something with that
        fields={tempMaintenancesFields}
      >
        <Modal
          title={t("tempmaintenances.edit.modals.create.title")}
          visible={maintenanceModalVisible}
          onOk={handleCreateRegMaintenance}
          cancelText={"Annuler"}
          onCancel={handleCancelCreation}
        >
          <Form.Item name={"beginningDate"}>
            <DatePickerFormField
              showLabel
              module="tempmaintenances.edit"
              field={"beginningDate"}
              required={true}
              format={DATE_FORMAT}
            />
          </Form.Item>
          <Form.Item name={"endingDate"}>
            <DatePickerFormField
              showLabel
              module="tempmaintenances.edit"
              field={"endingDate"}
              required={true}
              format={DATE_FORMAT}
            />
          </Form.Item>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="tempmaintenances.edit"
                field="reason"
                emptyErrorMessage={t("forms.errors.mandatory")}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default TempMaintenancesFormField;
