import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restPostHandlerWithSuffix,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { InterruptionHistoryNodeGetRequestDto } from "@state/interruptionHistoryNode/request/interruptionHistoryNode.get.request.dto";
import { InterruptionHistoryNodeItemResponseDto } from "@state/interruptionHistoryNode/response/interruptionHistoryNode.item.response.dto";
import { InterruptionHistoryNodeUpdateRequestDto } from "@state/interruptionHistoryNode/request/interruptionHistoryNode.update.request.dto";

const baseUrl = configuration.backendBaseUrl;
const interruptionHistoryUrlBySupervision =
  baseUrl + "/interruptionhistorynode/supervision/";
const interruptionHistoryUrl = baseUrl + "/interruptionhistorynode/";

export const requestPostInterruptionHistoriesNode = createEffect({
  handler: restPostHandlerWithSuffix<
    InterruptionHistoryNodeGetRequestDto,
    InterruptionHistoryNodeItemResponseDto[]
  >(interruptionHistoryUrlBySupervision),
});

export const requestUpdateInterruptionHistoriesNode = createEffect({
  handler: restUpdateHandler<
    InterruptionHistoryNodeUpdateRequestDto,
    InterruptionHistoryNodeItemResponseDto
  >(interruptionHistoryUrl),
});
