import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerFilteredBy,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { TempMaintenancesItemResponseDto } from "@state/tempmaintenances/dto/response/tempmaintenances.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { TempMaintenancesSaveRequestDto } from "@state/tempmaintenances/dto/request/tempmaintenances.save.request.dto";
import { TempMaintenancesDetailsResponseDto } from "@state/tempmaintenances/dto/response/tempmaintenances.details.response.dto";

const baseUrl = configuration.backendBaseUrl;
const tempMaintenancesUrl = baseUrl + "/tempmaintenances/";

export const requestGetTempMaintenancePage = createEffect({
  handler: restListHandlerPagination<Pageable<TempMaintenancesItemResponseDto>>(
    tempMaintenancesUrl
  ),
});

export const requestGetTempMaintenances = createEffect({
  handler: restListHandler<TempMaintenancesItemResponseDto>(
    tempMaintenancesUrl
  ),
});

export const requestGetTempMaintenancesForSupervision = createEffect({
  handler: restListHandlerFilteredBy<TempMaintenancesItemResponseDto>(
    tempMaintenancesUrl + "supervisions/"
  ),
});

export const requestGetTempMaintenance = createEffect({
  handler: restDetailsHandler<TempMaintenancesDetailsResponseDto>(
    tempMaintenancesUrl
  ),
});

export const requestCreateTempMaintenance = createEffect({
  handler: restCreationHandler<
    TempMaintenancesSaveRequestDto,
    TempMaintenancesDetailsResponseDto
  >(tempMaintenancesUrl),
});

export const requestUpdateTempMaintenance = createEffect({
  handler: restUpdateHandler<
    TempMaintenancesSaveRequestDto,
    TempMaintenancesDetailsResponseDto
  >(tempMaintenancesUrl),
});

export const requestDeleteTempMaintenance = createEffect({
  handler: restDeletionHandler(tempMaintenancesUrl),
});
