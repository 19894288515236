import { FunctionComponent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import SideMenu from "@components/menus/SideMenu";
import { authentifiedStore } from "@state/auth/AuthStore";
import Hamburger from "hamburger-react";

interface PageLayoutProps {
  noHeader?: boolean;
  noFooter?: boolean;
  noSideMenu?: boolean;
  children: string | ReactElement;
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({
  children,
  noHeader = false,
  // noFooter = false,
  noSideMenu = false,
}) => {
  const { t } = useTranslation();

  const { Sider, Content } = Layout;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Layout style={{ height: "100vh" }}>
      {!noHeader && (
        <header
          className={
            "d-flex flex-nowrap align-items-center justify-content-between px-1 py-3"
          }
        >
          <div
            className={
              "d-flex align-items-center flex-grow-1 flex-sm-grow-0 justify-content-between justify-content-sm-center"
            }
          >
            <Hamburger
              onToggle={() => {
                setCollapsed(!collapsed);
              }}
              size={24}
            />
            <Link to="/">
              <div className={"d-flex flex-row align-items-center ml-2"}>
                <div className="subtitle mr-3">WEB • MON</div>
                <img
                  alt={t("header.logo.alt")}
                  className="logo px-2 d-none d-sm-block"
                  src="/logo.png"
                />
              </div>
            </Link>
          </div>
          <div className="user d-sm-flex align-items-center d-none d-sm-block">
            <>
              <div className="d-flex flex-row align-items-end justify-content-center px-2">
                <div className="user-name mr-3">
                  Bienvenue {authentifiedStore.getState()?.user?.firstname}
                </div>
              </div>
            </>
          </div>
        </header>
      )}
      <Layout>
        {!noSideMenu && (
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            width="250px"
            collapsed={collapsed}
            onCollapse={(collapsed) => setCollapsed(collapsed)}
          >
            <SideMenu />
          </Sider>
        )}
        <Layout className="content-container position-relative">
          <Content className="content">
            <div className="position-relative">{children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
