import { FunctionComponent, MouseEvent } from "react";
import { Button } from "antd";

interface BasicButtonProps {
  type?: "button" | "submit" | "reset";
  icon?: JSX.Element;
  iconLeft?: boolean;
  variant?: string;
  text?: string | JSX.Element;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any;
  className?: string;
  isLoading?: boolean;
}

const BasicButton: FunctionComponent<BasicButtonProps> = ({
  type = "button",
  icon,
  iconLeft = false,
  variant = "default",
  text = "",
  disabled = false,
  onClick = undefined,
  isLoading = false,
  className = "",
}) => {
  return (
    <Button
      htmlType={type}
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
      className={`btn-${variant} ${
        text === "" ? "btn-icon" : ""
      } ${className} it-button`}
    >
      <div className="d-flex align-items-center justify-content-center position-relative text-hidden">
        {text !== "" && (
          <div className="text text-ellipsis font-weight-bold">{text}</div>
        )}
        {icon && <div className={`icon ${text ? "ml-2" : ""}`}>{icon}</div>}
      </div>
    </Button>
  );
};

export default BasicButton;
