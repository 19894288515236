import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import PageBody from "@components/bodies/PageBody";
import StatisticsAvailability from "@views/statistics/details/StatisticsAvailability";
import { useTranslation } from "react-i18next";
import CardTabContainer from "@components/containers/CardTabContainer";
import { Button, DatePicker, message, Select, Space } from "antd";
import { requestGetSupervision } from "@state/supervisions/SupervisionEffects";
import DownGraph from "@views/statistics/details/DownGraph";
import GlobalDownHistory from "@views/statistics/details/GlobalDownHistory";
import moment from "moment";
import { InterruptionHistoryGlobalItemResponseDto } from "@state/interruptionHistoryGlobal/dto/response/interruptionHistoryGlobal.item.response.dto";
import { requestPostInterruptionHistories } from "@state/interruptionHistoryGlobal/dto/InterruptionHistoryGlobalEffects";
import { StatisticsItemResponseDto } from "@state/statistics/dto/response/statistics.item.response.dto";
import { requestPostStatistics } from "@state/statistics/dto/StatisticsEffects";
import locale from "antd/es/date-picker/locale/fr_FR";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SupervisionNodeCurrentStatus } from "@state/supervisions/dto/response/supervision.details.response.dto";
import { requestPostInterruptionHistoriesNode } from "@state/interruptionHistoryNode/InterruptionHistoryNodeEffects";
import { InterruptionHistoryNodeItemResponseDto } from "@state/interruptionHistoryNode/response/interruptionHistoryNode.item.response.dto";
import NodeDownHistory from "@views/statistics/details/NodeDownHistory";

export interface Period {
  beginningDate: string;
  endingDate: string;
}

export interface Node {
  nodeId: string;
  name: string;
}

export interface NodeAndPeriod {
  node: string;
  beginningDate: string;
  endingDate: string;
}

const StatisticsScreen: FunctionComponent<RouteComponentProps> = () => {
  const FORM_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
  const thisWeek: Period = {
    beginningDate: moment()
      .subtract(8, "days")
      .startOf("day")
      .format(FORM_DATE_FORMAT),
    endingDate: moment()
      .subtract(1, "days")
      .endOf("day")
      .format(FORM_DATE_FORMAT),
  };
  const history = useHistory();
  const { supervisionId } = useParams<{ supervisionId: string }>();
  const [period, setPeriod] = useState<Period>(thisWeek);
  const [node, setNode] = useState<SupervisionNodeCurrentStatus[]>([]);
  const [selectedNode, setSelectedNode] = useState<Node>({
    nodeId: "all",
    name: "Global",
  });
  const [supervisionName, setSupervisionName] = useState<String | null>(null);
  useEffect(() => {
    if (supervisionId) {
      requestGetSupervision(supervisionId).then((result) => {
        if (result.ok && result.data) {
          setSupervisionName(result.data.name);
          setNode(result.data.nodeSupervisionCurrentStatus);
        } else {
          void message.error(result.errorMessage);
        }
      });
    }
  }, [supervisionId]);
  const [interruptionsGlobal, setInterruptionsGlobal] = useState<
    InterruptionHistoryGlobalItemResponseDto[] | []
  >([]);
  const [interruptionsAll, setInterruptionsAll] = useState<
    | InterruptionHistoryGlobalItemResponseDto[]
    | InterruptionHistoryNodeItemResponseDto[]
    | []
  >([]);
  const [interruptionsNode, setInterruptionsNode] = useState<
    InterruptionHistoryNodeItemResponseDto[] | []
  >([]);
  useEffect(() => {
    if (supervisionId) {
      if (selectedNode.nodeId === "all") {
        requestPostInterruptionHistories({
          id: supervisionId,
          dto: period,
        }).then((result) => {
          if (result.ok) {
            // @ts-ignore
            setInterruptionsGlobal(result.data);
            // @ts-ignore
            setInterruptionsAll(result.data);
          } else {
            if (result.errorMessage !== "") {
              void message.error(result.errorMessage);
            }
          }
        });
      } else {
        requestPostInterruptionHistoriesNode({
          id: supervisionId + "/node/" + selectedNode.nodeId,
          dto: period,
        }).then((result) => {
          if (result.ok) {
            // @ts-ignore
            setInterruptionsNode(result.data);
            // @ts-ignore
            setInterruptionsAll(result.data);
          } else {
            if (result.errorMessage !== "") {
              void message.error(result.errorMessage);
            }
          }
        });
      }
    }
  }, [supervisionId, selectedNode, period]);
  const [
    statistics,
    setStatistics,
  ] = useState<StatisticsItemResponseDto | null>(null);
  useEffect(() => {
    if (supervisionId) {
      let nodeAndPeriod: NodeAndPeriod = {
        node: selectedNode.nodeId,
        beginningDate: period.beginningDate,
        endingDate: period.endingDate,
      };
      requestPostStatistics({ id: supervisionId, dto: nodeAndPeriod }).then(
        (result) => {
          if (result.ok) {
            // @ts-ignore
            setStatistics(result.data);
          } else {
            void message.error(result.errorMessage);
          }
        }
      );
    }
  }, [supervisionId, selectedNode, period]);

  function handleHistoryChangeGlobal(
    newInterruptions: InterruptionHistoryGlobalItemResponseDto[]
  ) {
    setInterruptionsGlobal(newInterruptions);
  }

  function handleHistoryChangeNode(
    newInterruptions: InterruptionHistoryNodeItemResponseDto[]
  ) {
    setInterruptionsNode(newInterruptions);
  }

  function disableDate(current: moment.Moment) {
    return current && current > moment().endOf("day");
  }

  const { t } = useTranslation();
  const tabs = [
    { key: "metrics", tab: t("statistics.availability") },
    { key: "downGraph", tab: t("statistics.down") },
    { key: "history", tab: t("statistics.history") },
  ];
  const content = [
    {
      key: "metrics",
      node: <StatisticsAvailability statistics={statistics} />,
    },
    {
      key: "downGraph",
      node: <DownGraph interruptions={interruptionsAll} period={period} />,
    },
    {
      key: "history",
      node:
        selectedNode.nodeId === "all" ? (
          <GlobalDownHistory
            interruptions={interruptionsGlobal}
            period={period}
            onChange={handleHistoryChangeGlobal}
          />
        ) : (
          <NodeDownHistory
            interruptions={interruptionsNode}
            period={period}
            onChange={handleHistoryChangeNode}
          />
        ),
    },
  ];
  const { RangePicker } = DatePicker;
  return (
    <PageLayout>
      <>
        <PageBody>
          <div className="my-3">
            <div className="d-flex align-items-center justify-content-left flex-wrap">
              <Button
                className="m-2"
                onClick={() => history.push(`/supervisions/${supervisionId}/`)}
              >
                <ArrowLeftOutlined /> {t("buttons.back")}
              </Button>
            </div>
          </div>
          <CardTabContainer
            title={
              <div className="d-flex flex-column align-items-stretch flex-sm-row">
                <div className="w-100 d-flex justify-content-between mb-3 flex-column flex-sm-row">
                  {t("statistics.title") +
                    " " +
                    supervisionName +
                    " (" +
                    selectedNode.name +
                    ")"}
                  <Space className="d-flex align-items-center justify-content-center flex-wrap">
                    <RangePicker
                      locale={locale}
                      size="small"
                      defaultValue={[
                        moment(thisWeek.beginningDate),
                        moment(thisWeek.endingDate),
                      ]}
                      ranges={{
                        "Les 7 derniers jours": [
                          moment(thisWeek.beginningDate),
                          moment(thisWeek.endingDate),
                        ],
                        "Le mois dernier": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      onCalendarChange={(date, dateStrings) => {
                        setPeriod({
                          beginningDate:
                            dateStrings[0] === ""
                              ? moment()
                                  .subtract(7, "days")
                                  .startOf("day")
                                  .format(FORM_DATE_FORMAT)
                              : moment(dateStrings[0])
                                  .startOf("day")
                                  .format(FORM_DATE_FORMAT),
                          endingDate:
                            dateStrings[1] === ""
                              ? moment().format(FORM_DATE_FORMAT)
                              : moment(dateStrings[1]).date() ===
                                moment().date()
                              ? moment().format(FORM_DATE_FORMAT)
                              : moment(dateStrings[1])
                                  .endOf("day")
                                  .format(FORM_DATE_FORMAT),
                        });
                      }}
                      disabledDate={disableDate}
                    />
                    <Select
                      options={[{ value: "all", label: "Global" }].concat(
                        node?.map((item) => {
                          return {
                            value: item.supervisionNodeId.id,
                            label: item.supervisionNodeId.name,
                          };
                        })
                      )}
                      defaultValue={"all"}
                      onSelect={(value) => {
                        setSelectedNode({
                          nodeId: value,
                          name:
                            node.find(
                              (value1) => value1.supervisionNodeId.id === value
                            )?.supervisionNodeId.name || "Global",
                        });
                      }}
                    />
                  </Space>
                </div>
              </div>
            }
            tabs={tabs}
            content={content}
          />
        </PageBody>
      </>
    </PageLayout>
  );
};

export default StatisticsScreen;
